/* eslint-disable eqeqeq */
import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { CustomModal } from "../../components/UI/Modal";
import { postReportAbuse as postReportProfile } from "../Profile/Service";
import {
  deletePost,
  //  postCommentReport,
  postReportAbuse as postReportFeed,
} from "../Feed/Service";
import { useToast } from "@chakra-ui/react";

const ReportModalPage = ({
  handleModalStatus,
  reportAbuseData,
  deleteModal,
  setDeleteModal,
  isOwnPostForReport,
  modalStatus,
  setModalStatus,
  fromProfile,
  fromFeed,
  postId,
  userUuid,
}) => {
  const [alreadyReported, setAlreadyReportedModal] = useState(false);
  const [submissionModal, setsubmissionModal] = useState(false);
  const [subReasons, setSubReasons] = useState([]);
  const [subReasonModalStatus, setSubReasonModalStatus] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const toast = useToast();
  const handleProfileReport = async (reason, userUuid) => {
    setSubReasonModalStatus(false);
    const payload = {
      abuseId: reason.id,
      remarks: reason.name,
      to: userUuid,
    };
    const res = await postReportProfile(payload);
    if (res.code == 422) {
      setModalStatus(false);
      setsubmissionModal(false);
      setAlreadyReportedModal(true);
    }

    if (res.status == "success") {
      setModalStatus(false);
      setsubmissionModal(true);
      setAlreadyReportedModal(false);
    }
  };

  const handleFeedReport = async (reason, postid) => {
    const payload = {
      postId: postid,
      abuseId: reason.id,
      remarks: reason.name,
    };

    const res = await postReportFeed(payload);
    if (res.code == 422) {
      setModalStatus(false);
      setsubmissionModal(false);
      setAlreadyReportedModal(true);
    }

    if (res.status == "success") {
      setModalStatus(false);
      setsubmissionModal(true);
      setAlreadyReportedModal(false);
    }
  };

  const handleSubReasons = (reason) => {
    setModalStatus(false);
    setModalTitle(reason.name);
    setSubReasons(reason.subReason);
    setSubReasonModalStatus(true);
  };

  const handleReport = (reason, postId, userUuid) => {
    fromProfile && handleProfileReport(reason, userUuid);
    fromFeed && handleFeedReport(reason, postId);
  };

  const onDeletePost = async (value) => {
    setDeleteModal(false);
    const res = await deletePost(value);

    if (res.status == "post deleted") {
      toast({
        position: "top",
        title: "Deleted Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } else {
      toast({
        position: "top",
        title: "Deleted Successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    setDeleteModal(false);
    handleModalStatus();
  };
  return (
    <>
      {!isOwnPostForReport && (
        <>
          <CustomModal
            styles={{ padding: "0px", borderRadius: "30px" }}
            crossIcon={false}
            size="sm"
            title="Report"
            show={modalStatus}
            modalBodyClassName="reportModalItems"
            handleClose={() => setModalStatus(false)}
            className="user_list_modal feed-report-wrap"
            modalBodyStyle={{ padding: "0" }}
          >
            {reportAbuseData?.map((reason, index) => (
              <div key={index} className="profileDotListItem ">
                <Button
                  className="nested_data"
                  onClick={() => {
                    reason.subReason.length > 0
                      ? handleSubReasons(reason)
                      : handleReport(reason, postId, userUuid);
                  }}
                  style={{
                    justifyContent: "space-between",
                    fontSize: "13px",
                    height: "50px",
                    padding: "0px 10px",
                    textTransform: "none",
                  }}
                >
                  <p style={{ marginTop: "5px", marginBottom: "5px" }}>
                    {reason.name}
                  </p>
                  {reason?.subReason?.length > 0 && (
                    <i className="bi bi-chevron-right"></i>
                  )}
                </Button>
              </div>
            ))}
          </CustomModal>
          {subReasons.length ? (
            <CustomModal
              styles={{ padding: "0px", borderRadius: "30px" }}
              crossIcon={false}
              size="sm"
              title={modalTitle}
              show={subReasonModalStatus}
              modalBodyClassName="reportModalItems"
              handleClose={() => setSubReasonModalStatus(false)}
              className="user_list_modal"
              modalBodyStyle={{ padding: "0" }}
            >
              {subReasons?.map((reason, index) => (
                <div key={index} className="profileDotListItem ">
                  <Button
                    className="nested_data"
                    onClick={() => handleReport(reason, postId, userUuid)}
                    style={{
                      justifyContent: "space-between",
                      fontSize: "13px",
                      height: "50px",
                      padding: "0px 10px",
                      textTransform: "none",
                    }}
                  >
                    <p style={{ marginTop: "5px", marginBottom: "5px" }}>
                      {reason.name}
                    </p>
                    {reason?.subReason?.length > 0 && (
                      <i className="bi bi-chevron-right"></i>
                    )}
                  </Button>
                </div>
              ))}
            </CustomModal>
          ) : (
            ""
          )}
          {alreadyReported ? (
            <CustomModal
              title
              fromAlready
              show={alreadyReported}
              handleClose={() => setAlreadyReportedModal(false)}
              crossIcon={true}
              className="user_list_modal"
              size="sm"
            >
              <p
                style={{
                  fontSize: "23px",
                  marginTop: "38px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                We’ve already received your Submission!
              </p>
              <center style={{ marginBottom: "20px", marginTop: "30px" }}>
                <Button
                  style={{
                    width: "35%",
                    marginTop: "20px",
                    borderRadius: "20px",
                    backgroundColor: "#D8463D",
                    color: "white",
                  }}
                  type="button"
                  onClick={() => setAlreadyReportedModal(false)}
                >
                  OK
                </Button>
              </center>
            </CustomModal>
          ) : (
            ""
          )}
          {submissionModal ? (
            <CustomModal
              modalBodyStyle={{ height: "296px", padding: "30px" }}
              show={submissionModal}
              handleClose={() => setsubmissionModal(false)}
              className="user_list_modal"
              crossIcon={false}
            >
              <div className="report_submission_modal">
                <div className="report_submission_inner">
                  <div className="right_green_icon" />
                </div>
                <p className="first_heading">
                  We have received your submission
                </p>
                <div className="main_content">
                  <p>Your feedback is important in helping us</p>
                  <p>keep the Cachy Community safe.</p>
                </div>

                <Button
                  className="done_button"
                  type="button"
                  onClick={() => setsubmissionModal(false)}
                >
                  OK
                </Button>
              </div>
            </CustomModal>
          ) : (
            ""
          )}
        </>
      )}
      {isOwnPostForReport ? (
        <CustomModal
          crossIcon={false}
          show={deleteModal}
          handleClose={() => setDeleteModal(false)}
          className="delete_modal"
        >
          <Button onClick={() => onDeletePost(postId)}>Delete</Button>
          <Button onClick={() => setDeleteModal(false)}>Cancel</Button>
        </CustomModal>
      ) : (
        ""
      )}
    </>
  );
};

export default ReportModalPage;
