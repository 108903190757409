import React, { useEffect, useState, Fragment } from "react";
import {
  Accordion,
  Container
} from "react-bootstrap";
import { Footer } from "../../components/shared/Footer";
import PublicNavbar from "../../components/shared/PublicNavbar";
import { getFaq } from "../../publicApiCalls/Service";

export const Help = () => {
  const [faq, setFaq] = useState();
  useEffect(() => {
    const faqData = async () => {
      const res = await getFaq();
      const {code,data}= res;
      if (code === 200) {
        setFaq(data);
      }
    }
    faqData();
  }, []);
  return (
    <>
      <PublicNavbar />
      <div className="help_wrapper help faq-wrapper">
        <div className="body">
          <Container className="faq-head">
            <h5>How can we help you?</h5>
          </Container>
          {faq && faq.map((item, index) => {
            const { question, ans_heading, answer } = item;
            const indexData = index.toString();
            return (
              <Fragment key={index}>
                <Accordion defaultActiveKey={"0"}>
                  <Accordion.Item eventKey={indexData}>
                    <Accordion.Header className="question-wrap">{question}</Accordion.Header>
                    <Accordion.Body className="answer-wrap">
                      <h2 className="answer-heading">{ans_heading}</h2>
                      <ul className="answer-list">
                        {answer && answer.map((ansItem, idx) =>
                          <li key={idx}>{ansItem?.answer_list}</li>
                        )}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Fragment>
            )
          })}
        </div>
      </div>
      <Footer extraItems />
    </>
  );
}
