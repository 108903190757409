import React, { Suspense } from "react";
import { Nav } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import { useUser } from "../../context/UserProvider";
import LeftFooter from "./LeftFooter";
import SuggestionListOther from "./SuggestionListOther";

export default function VerticalNav(props) {
  let location = useLocation();
  const { userData } = useUser();

  const LogoutHandler = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <>
      <div className="vertical_nav_wrap">
        <Nav className="vertical_nav_container">
          {location.pathname.includes(`${userData?.profile?.username}`) &&
          !location.pathname.includes("edit") ? (
            <>
              <NavLink
                activeClassName="active"
                className="nav-link"
                to={`/${userData?.profile?.username}`}
              >
                <i className="icon profile"></i>
                <span>Profile</span>
              </NavLink>
              <NavLink
                activeClassName="active"
                className="nav-link"
                to={`/edit/${userData?.profile?.username}`}
              >
                <i className="icon settings"></i> Settings
              </NavLink>

              <Nav.Link eventKey="link-2" onClick={LogoutHandler}>
                <i className="icon logout"></i>Log Out
              </Nav.Link>
            </>
          ) : (
            location.pathname.includes("edit") && (
              <>
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to={`/edit/${userData?.profile?.username}`}
                >
                  <i className="icon edit_profile"></i>{" "}
                  <span>Edit Account</span>
                </NavLink>
              </>
            )
          )}
          {!location.pathname.includes("edit") &&
            !location.pathname.includes(`${userData?.profile?.username}`) && (
              <div className="suggestion_other_wrap">
                <Suspense fallback={<div>Loading...</div>}>
                  <SuggestionListOther
                    popularLength="3"
                    peopleYouMayKnowLength="4"
                  />
                </Suspense>
                <LeftFooter />
              </div>
            )}
        </Nav>
        {location.pathname.includes("edit") && (
          <>
            <h5>Settings</h5>
            <Nav className="flex-column">
              <NavLink
                activeClassName="active"
                className="nav-link"
                to={`/edit-privacy-settings`}
              >
                <i className="icon lock"></i> <span> Privacy Settings</span>
              </NavLink>
              <NavLink
                activeClassName="active"
                className="nav-link"
                to={`/edit-help-support`}
              >
                <i className="icon help"></i> <span> Help and Support</span>
              </NavLink>
            </Nav>
          </>
        )}
      </div>
    </>
  );
}
