import React from "react";
import { Modal } from "react-bootstrap";

/**
 * @author
 * @function CustomModal
 **/

export const CustomModal = ({
  modalBodyClassName,
  show,
  handleClose,
  title,
  size,
  className,
  children,
  crossIcon,
  modalBodyStyle,
  fromAlready,
}) => {
  const alreadyReportStyle = {
    borderBottom: "none",
  };

  return (
    <>
      <div>
        <Modal
          backdrop="true"
          show={show}
          onHide={handleClose}
          size={size}
          centered
          className={`common-modal-wrapper ${className}`}
          animation={false} 
        >
          {title ? (
            <div>
              <Modal.Header
                closeButton
                style={fromAlready && alreadyReportStyle}
              >
                <Modal.Title>{title}</Modal.Title>
              </Modal.Header>
            </div>
          ) : (
            (crossIcon === false ? crossIcon : true) && (
              <span className="close_modal" onClick={handleClose}>
                <i className="icon cross"></i>
              </span>
            )
          )}

          <Modal.Body style={modalBodyStyle} className={modalBodyClassName}>
            {children}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
