import { useState } from "react";
import {
  // Button,
  Container,
  Nav,
  Navbar,
} from "react-bootstrap";
import { Logo } from "../../pages/auth/shared/Logo";
import { Link } from "react-router-dom";

import { useUser } from "../../context/UserProvider";
// import Notifications from "./Notifications";
import { Sling as Hamburger } from "hamburger-react";
import VerticalForHam from "./VerticalForHam";
import AvatarImage from "./AvatarImage";
/**
 * @author
 * @function AppNavbar
 **/

export const AppNavbar = (props) => {
  const { userData, userProfileData } = useUser();
  const [hamburger, setHamburger] = useState(false);
  // const [isSerachOpen, setSearchOpen] = useState(false);
  // const ref = useRef();

  // const handleSearchClose = () => setSearchOpen(false);
  // const handleSearchOpen = () => setSearchOpen(true);

  // useOutsideClick({
  //   ref: ref,
  //   handler: () => handleSearchClose(),
  // });

  return (
    <>
      <div className="app_nav sticky-top">
        <Container className="navbar_container">
          <Navbar expand="lg">
            <Navbar.Brand>
              <Link to="/">
                <Logo />
              </Link>
            </Navbar.Brand>

            <Navbar className="left_nav" id="basic-navbar-nav">
              {/* <div style={{display:`${isSerachOpen?"none":"flex"}`,height:"42px",marginTop:"6px",width:"100%"}}></div> */}

              {/* <div className="search_box">
                {isSerachOpen && (
                  <InputGroup className="search_group" ref={ref}>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<i className="icon search_grey"></i>}
                    />
                    <Input placeholder="Search Post" />
                    <InputRightElement
                      children={<Button type="submit">Search </Button>}
                    />
                  </InputGroup>
                  )}
                </div> */}

              <Nav>
                {/* <Nav.Link onClick={handleSearchOpen}>
                  <i className="icon search"></i>
                </Nav.Link> */}

                {/* <Popover className="notification_popover">
                  <PopoverTrigger>
                    <button
                      style={{ cursor: "pointer" }}
                      className="nav-link notification_link"
                    >
                      <i className="icon notification"></i>
                      
                    </button>
               
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow /> */}
                {/* <PopoverCloseButton /> */}
                {/* <PopoverBody>
                      <Notifications />
                    </PopoverBody>
                  </PopoverContent>
                </Popover> */}
                {/* <div style={{backgroundColor:"black", width:"2px",height:"25px",marginRight:"10px"}}></div> */}
                {/* <Link
                  to={`/${userData?.profile?.username}`}
                  className="nav-link "
                > */}
                <AvatarImage size="sm" />
                {/* </Link> */}
                <Link
                  to={`/${userData?.profile?.username}`}
                  className="nav-link username_link"
                >
                  {`${userProfileData?.firstName} ${userProfileData?.lastName}`}
                </Link>
              </Nav>
              <span className="hamburger">
                <Hamburger
                  size={25}
                  onToggle={() => setHamburger(!hamburger)}
                />
              </span>
            </Navbar>
          </Navbar>
          {hamburger && <VerticalForHam />}
        </Container>
      </div>
    </>
  );
};
