import React, { lazy, Suspense, useEffect, useState } from "react";
import { Route, Switch } from "react-router";

import { useLocation, Redirect } from "react-router-dom";

import { AppNavbar } from "../shared/AppNavbar";

import { FeedProvider } from "../../context/FeedProvider";
import { Container } from "react-bootstrap";
import EditProfile from "../../pages/Profile/EditProfile";
import PrivacySettings from "../../pages/PrivacySettings";
import HelpAndSupport from "../../pages/HelpAndSupport";

import Feed from "../../pages/Feed";
import LeftFooter from "../shared/LeftFooter";
import LoginModal from "../../pages/LoginModal/LoginModal";
import { getUserProfileByUsername } from "../../pages/Profile/Service";
import BrokenPage from "../shared/BrokenPage";
import VerticalNav from "../shared/VerticalNav";

const ProfileCard = lazy(() => import("../shared/ProfileCard"));
const Profile = lazy(() => import("../../pages/Profile"));
const SuggestionListOther = lazy(() => import("../shared/SuggestionListOther"));

const PrivateLayout = () => {
  let location = useLocation();

  const FeedRender = () => {
    const [error, setError] = useState(false);

    const handleMismatchRoute = async () => {
      const splitedUrl = window.location.href.split("/");
      switch (splitedUrl[3]) {
        case "feed":
          break;
        case "edit":
          break;
        case "edit-privacy-settings":
          break;
        case "edit-help-support":
          break;
        default:
          const res = await getUserProfileByUsername(splitedUrl[3]);
          if (res?.response?.status === 435) {
            setError(true);
          }
          break;
      }
    };

    useEffect(() => {
      handleMismatchRoute();
    }, []);

    return (
      <div className="wrapper">
        <AppNavbar />
        {!error ? (
          <div className="main_body">
            <Container>
              <div className="feed_row">
                <div
                  className={`feed_col_left sticky-top ${
                    !location.pathname.includes("feed") ? "is_shadow" : ""
                  }`}
                >
                  {location.pathname.includes("feed") && (
                    <>
                      <div>
                        <Suspense fallback={<div>Loading...</div>}>
                          <ProfileCard />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <SuggestionListOther
                              popularLength="2"
                              peopleYouMayKnowLength="2"
                            />
                          </div>
                        </Suspense>
                      </div>
                      <LeftFooter />
                    </>
                  )}
                  {!location.pathname.includes("feed") && <VerticalNav />}
                </div>
                <div className="feed_col_middle">
                  <FeedProvider>
                    <Switch>
                      <Route exact path="/">
                        <Redirect to="/feed" />
                      </Route>
                      <Route exact path="/feed">
                        <Feed />
                      </Route>
                      <Route exact path="/feed/:postId">
                        <Feed />
                      </Route>
                      <Route exact path="/PreLogin">
                        <LoginModal />
                      </Route>
                      <Route exact path="/edit-privacy-settings">
                        <PrivacySettings />
                      </Route>
                      <Route exact path="/edit-help-support">
                        <HelpAndSupport />
                      </Route>
                      <Route exact path="/edit/:username">
                        <EditProfile />
                      </Route>
                      <Route exact path="/:username">
                        <Suspense fallback={<div>Loding...</div>}>
                          <Profile />
                        </Suspense>
                      </Route>
                    </Switch>
                  </FeedProvider>
                </div>
              </div>
            </Container>
          </div>
        ) : (
          <BrokenPage
            heading_1="Sorry, this page isn’t available"
            heading_2="This link you followed may be broken, or the page may have been removed."
          />
        )}
      </div>
    );
  };

  return (
    <>
      <FeedRender />
    </>
  );
};

export default PrivateLayout;
