import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { FEED_IMG_URL } from "../../utils/config";
import Avatar from "react-avatar";
import { addViewToFeed } from "./Service";

const StoryItem = ({
  setStoryOpen,
  story,
  setPlayerData,
  setPoster,
  showPlayer,
  handleSetVideoUrl,
}) => {
  const addViewsToPostHandler = async (id) => {
    const payload = {
      postIds: [id],
    };
    await addViewToFeed(payload);
  };
  return (
    <div
      className="story_col"
      style={{
        backgroundImage: `url(${FEED_IMG_URL}${story?.coverImg?.remoteid})`,
      }}
      onClick={() => {
        setStoryOpen(true);
        setPlayerData(story);
        addViewsToPostHandler(story?.postId);
        setPoster(`${FEED_IMG_URL}${story?.coverImg?.remoteid}`);
        showPlayer();
        handleSetVideoUrl(`${FEED_IMG_URL}${story?.video?.remoteid}`);
      }}
    >
      <div
        style={{ position: "relative", top: "8px", left: "8px", width: "43px" }}
      >
        <CircularProgressbarWithChildren
          style={{ position: "absolute" }}
          value={100}
          strokeWidth={3}
          styles={buildStyles({
            pathColor: story.isView ? "#FFFFFF" : "#169D59",
            trailColor: "none",
          })}
        >
          <Avatar
            size={35}
            round={true}
            maxinitials={2}
            name={`${story.firstName} ${story.lastName}`}
          />
        </CircularProgressbarWithChildren>
      </div>

      <h5 className="bottom_text">
        {story.firstName.charAt(0).toUpperCase() +
          story.firstName.slice(1) +
          " "}
        {story.lastName.charAt(0).toUpperCase() + story.lastName.slice(1)}
      </h5>
    </div>
  );
};

export default StoryItem;
