import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Footer } from "../../components/shared/Footer";
import PublicNavbar from "../../components/shared/PublicNavbar";
import { getPublicPageContent } from "../../publicApiCalls/Service";
import isEmpty from "is-empty";
import PublicPageSkeleton from "../../components/Skeleton/PublicPage";

export default function Terms() {
  const [contentData, setContentData] = useState({});
  const getPrivacyPolicyContentHanlder = async () => {
    const res = await getPublicPageContent("terms-and-conditions");
    if (res.code === 200) {
      setContentData(res.data);
    }
  };

  useEffect(() => {
    getPrivacyPolicyContentHanlder();
  }, []);
  return (
    <>
      <PublicNavbar />
      <div className="public_wrapper" style={{ padding: "0" }}>
        <p style={{ textAlign: "center", marginTop: "45px" }}>
          Terms & Conditions
        </p>
        <div className="body">
          {!isEmpty(contentData?.content) ? (
            <Container>
              <div
                dangerouslySetInnerHTML={{ __html: `${contentData.content}` }}
              />
            </Container>
          ) : (
            <PublicPageSkeleton />
          )}
        </div>
      </div>
      <Footer extraItems />
    </>
  );
}
