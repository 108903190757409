import axios from "axios";
import { TYPE } from "../../utils/config";

export const userSignupPreAuth = async (payload) => {
  try {
    const { data } = await axios.get(
      `cachy/v1/accounts/${TYPE.fcm}/login/${payload.token}/${payload.number}/${payload.deviceId}/${payload.deviceName}`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const sendOTP = async (payload) => {
  try {
    const { data } = await axios.get(
      `cachy/v1/accounts/${TYPE.sms}/code/${payload.number}?client=CACHYWEB&challenge=${payload.challenge}`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const verifyOTP = async (code, payload) => {
  try {
    const { data } = await axios.put(`cachy/v1/accounts/code/${code}`, payload);
    return data;
  } catch (err) {
    return err;
  }
};

export const userLogin = async (payload) => {
  try {
    const { data } = await axios.get(
      `cachy/v1/accounts/fcm/login/${payload.token}/${payload.username}/${payload.deviceId}/${payload.deviceName}/`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const accountVerifyLogin = async (username, payload) => {
  try {
    const { data } = await axios.put(
      `cachy/v1/accounts/verify/${username}`,
      payload
    );
    return data;
  } catch (err) {
    return err;
  }
};

export const accountTokenRefresh = async (payload) => {
  try {
    const { data } = await axios.put(`cachy/v1/accounts/gcm/`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const getAuthHeaders = async () => {
  try {
    const { data } = await axios.get(`cachy/v1/storage/auth`);
    return data;
  } catch (err) {
    return err;
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  userSignupPreAuth,
  sendOTP,
  verifyOTP,
  userLogin,
  accountTokenRefresh,
  getAuthHeaders,
};
