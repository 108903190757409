import React, { useEffect, useCallback, useState, useRef } from "react";
import isEmpty from "is-empty";
import { useUser } from "../../context/UserProvider";
import { FeedList } from "./FeedList";
import { CustomModal } from "../../components/UI/Modal";

import {
  commentLike,
  feedLike,
  feedShare,
  getAllPosts,
  getCommentsByPost,
  getStories,
  postComment,
  viewsOnPost,
  singlePostView,
  addViewToFeed,
} from "./Service";

import { getReportAbuseFeed } from "../Feed/Service";

import Stories from "./Stories";
import { useHistory } from "react-router-dom";

import Suggestions from "./Suggestions";
import { getPopularAndPeopleYouMayKnow } from "../../components/layout/Service";
import FeedSkeleton from "./FeedSkeleton";
import {
  Avatar,
  Grid,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useToast,
} from "@chakra-ui/react";
import { followUser } from "../Followers/Service";
import { unfollowUser } from "../Following/Service";
import { COPY_BASE_URL, FEED_IMG_URL } from "../../utils/config";
import { useFeed } from "../../context/FeedProvider";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share";
import CustomInput from "./CustomInput";
import { getAuthHeaders } from "../auth/Service";
import ReportModalPage from "../ReportModal/ReportModal";
import { timeGenerator } from "../../utils";
import ContributorsDetailsInner from "./ContributorsDetailsInner";
import StoryStatus from "../../components/Modal/StoryStatus";

/**
 * @author
 * @function Feed
 **/

const Feed = () => {
  const history = useHistory();
  const observer = useRef();
  const ref = useRef(null);
  const toast = useToast();

  const { onSubmitCommentsOnPost, commentsOnPost } = useFeed();
  const { userData, userProfileData, setUserProfileData } = useUser();
  const [showPlayer, setShowPlayer] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoPoster, setVideoPoster] = useState("");
  const [feedList, setFeedList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [playerData, setPlayerData] = useState({});
  const [storiesList, setStoriesList] = useState([]);
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);
  const [poplarList, setPoplarList] = useState([]);
  const [peopleYouMayKnowList, setPeopleYouMayKnowList] = useState([]);
  const [isFeedLoading, setIsFeedLoading] = useState(false);
  const [postFeedComment, setPostFeedComment] = useState("");
  const [isReplying, setIsReplying] = useState(false);
  const [message, setMessage] = useState("");
  const [reportAbuseData, setReportAbuseData] = useState();
  const [commentForReply, setCommentForReply] = useState();
  const [heartIconAnimat, setHeartIconAnimat] = useState(false);
  const [comment, setComment] = useState("");
  const [dataIndex, setDataIndex] = useState(0);
  const [canComment, setCanComment] = useState("");
  const [storyOpen, setStoryOpen] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleCloseSuggestionModal = () => setShowSuggestionModal(false);
  const handleOpenSuggestionModal = () => setShowSuggestionModal(true);

  const handleShowPlayer = () => setShowPlayer(true);
  const handleSetVideoUrl = (url) => setVideoUrl(url);
  const isOwnPostForReport = userData?.uuid == playerData?.uuid;
  const handleReply = (comment) => {
    setComment(`@${comment?.un} `);
    setIsReplying(true);
    ref?.current?.focus();
    setCommentForReply(comment);
  };

  const handleClosePlayer = () => {
    setShowPlayer(false);
    onSubmitCommentsOnPost([]);
    setStoryOpen(false);
  };

  const getAllPostsHandler = useCallback(async (pages = 1) => {
    setIsLoading(true);
    setIsFeedLoading(true);
    const res = await getAllPosts(pages);
    setHasMore(res?.more);
    if (!isEmpty(res.data)) {
      for (const data of res.data) {
        data.comment = "";
      }
      setFeedList((prev) => [...prev, ...res?.data]); //we can replace this with, setFeedList(...res?.data)
      setIsLoading(false);
      setIsFeedLoading(false);
    }
  }, []);

  const lastItemRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          if (feedList.length > 0) {
            getAllPostsHandler(page);
            setPage((prev) => prev + 1);
          } else {
            setHasMore(false);
          }
        }
      });

      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line no-use-before-define
    [feedList, getAllPostsHandler, hasMore, isLoading, page]
  );

  const feedLikeHandler = async (postId) => {
    const payload = {
      postId,
      isLike: true,
    };

    const tempPost = [...feedList];

    tempPost.forEach((el) => {
      if (el.postId === postId) {
        el.isLiked = true;
      }
    });
    setFeedList(tempPost);
    await feedLike(payload);
  };

  const handleFeedLike = (data) => {
    setHeartIconAnimat(!heartIconAnimat);
    if (data.isLiked) {
      setPlayerData({ ...playerData, isLiked: !playerData.isLiked });
      feedUnlikeHandler(data.postId);
    } else {
      setPlayerData({ ...playerData, isLiked: !playerData.isLiked });
      feedLikeHandler(data.postId);
    }
  };

  const feedUnlikeHandler = async (postId) => {
    const payload = {
      postId,
      isLike: false,
    };
    const tempPost = [...feedList];
    tempPost.forEach((el) => {
      if (el.postId === postId) {
        el.isLiked = false;
      }
    });
    setFeedList(tempPost);
    await feedLike(payload);
  };

  const feedShareHandler = async (postId) => {
    const payload = {
      postId,
      isStory: true,
    };
    await feedShare(payload);
    getStoriesHandler();
  };
  const postCommentHandler = async (e, postId) => {
    e.preventDefault();
    const tempData = [...feedList];
    const findComment = tempData.find((feed) => {
      return feed.postId === postId;
    });

    const payload = {
      postId,
      comment: findComment.comment,
    };
    const res = await postComment(payload);

    res &&
      res?.response?.status === 403 &&
      setMessage(res.response.data.message);

    tempData.forEach((feed) => {
      feed.comment = "";
    });

    setFeedList(tempData);
  };

  const postCommentFromFeed = async (e, postId) => {
    e.preventDefault();
    setPostFeedComment("");

    const payload = {
      postId,
      comment: postFeedComment,
    };

    await postComment(payload);
    getCommentsByPostHandler(postId);
  };

  const handleChangeComment = (e, postId) => {
    const { value } = e.target;
    const tempData = [...feedList];

    for (const feed of tempData) {
      if (feed.postId === postId) {
        feed.comment = value;
      }
    }

    setFeedList(tempData);
  };

  const getStoriesHandler = useCallback(async () => {
    const res = await getStories();
    if (res.data) {
      setStoriesList(res.data);
    }
  }, []);

  const getPopularAndPeopleYouMayKnowHanlder = async () => {
    setIsLoading(true);
    const res = await getPopularAndPeopleYouMayKnow();

    setPoplarList(res.popularUsers);
    setPeopleYouMayKnowList(res.youMayKnow);
    setIsLoading(false);
  };

  useEffect(() => {
    getPopularAndPeopleYouMayKnowHanlder();
    return () => getPopularAndPeopleYouMayKnowHanlder();
  }, []);

  const unfollowUserHandler = async (id) => {
    setUserProfileData((prev) => ({
      ...prev,
      following: parseInt(userProfileData.following) - 1,
    }));
    setPeopleYouMayKnowList((prev) =>
      prev.map((value) => {
        if (value.uuid == id) {
          return {
            ...value,
            isFollow: 0,
          };
        } else {
          return {
            ...value,
          };
        }
      })
    );
    setPoplarList((prev) =>
      prev.map((value) => {
        if (value.uuid === id) {
          return {
            ...value,
            isFollow: 0,
          };
        } else {
          return {
            ...value,
          };
        }
      })
    );
    const payload = {
      unfollowUuids: [id],
    };
    await unfollowUser(payload);
  };

  const followUserHandler = async (id, privateAccount) => {
    if (privateAccount) {
      setPeopleYouMayKnowList((prev) =>
        prev.map((value) => {
          if (value.uuid == id) {
            return {
              ...value,
              isFollow: 2,
            };
          } else {
            return {
              ...value,
            };
          }
        })
      );
      setPoplarList((prev) =>
        prev.map((value) => {
          if (value.uuid == id) {
            return {
              ...value,
              isFollow: 2,
            };
          } else {
            return {
              ...value,
            };
          }
        })
      );
    } else {
      setUserProfileData((prev) => ({
        ...prev,
        following: parseInt(userProfileData.following) + 1,
      }));
      setPeopleYouMayKnowList((prev) =>
        prev.map((value) => {
          if (value.uuid == id) {
            return {
              ...value,
              isFollow: 1,
            };
          } else {
            return {
              ...value,
            };
          }
        })
      );
      setPoplarList((prev) =>
        prev.map((value) => {
          if (value.uuid == id) {
            return {
              ...value,
              isFollow: 1,
            };
          } else {
            return {
              ...value,
            };
          }
        })
      );
    }

    const payload = {
      followingUuids: [id],
    };
    await followUser(payload);
  };

  const getCommentsByPostHandler = async (postId) => {
    const res = await getCommentsByPost(postId);
    setCanComment(res.canComment);
    if (res.data) {
      onSubmitCommentsOnPost(res.data);
    }
  };

  var images = [];

  const addViewsToPostHandler = async (id) => {
    const payload = {
      postIds: [id],
    };
    await addViewToFeed(payload);
  };

  const onFeedClickHandler = (data, index) => {
    setDataIndex(index);
    addViewsToPostHandler(data?.postId);
    getCommentsByPostHandler(data?.postId);
    setPlayerData(data);
    setVideoPoster(`${FEED_IMG_URL}${data?.coverImg?.remoteid}`);
    handleShowPlayer();
    handleSetVideoUrl(`${FEED_IMG_URL}${data?.video?.remoteid}`);
    viewsOnPostHanlder(data.postId);
  };

  const viewsOnPostHanlder = async (postId) => {
    await viewsOnPost(postId);
  };

  const handleCommentLike = (comment, postId, reply = false) => {
    if (comment?.isLiked) {
      commentUnlikeHandler(comment?.id, postId, reply);
    } else {
      commentLikeHandler(comment?.id, postId, reply);
    }
  };

  const commentLikeHandler = async (id, postId, reply) => {
    const payload = {
      commentId: id,
      isLike: true,
      isReplyLike: reply,
    };

    const tempComments = [...commentsOnPost];

    tempComments.forEach((el) => {
      if (el.id === id) {
        el.isLiked = true;
        el.likesCount = parseInt(el.likesCount) + 1;
      }
    });
    await commentLike(payload);
    onSubmitCommentsOnPost(tempComments);
    getCommentsByPostHandler(postId);
  };

  const commentUnlikeHandler = async (id, postId, reply) => {
    const payload = {
      commentId: id,
      isLike: false,
      isReplyLike: reply,
    };

    const tempComments = [...commentsOnPost];
    tempComments.forEach((el) => {
      if (el.id === id) {
        el.isLiked = false;
        el.likesCount = parseInt(el.likesCount) - 1;
      }
    });
    await commentLike(payload);
    onSubmitCommentsOnPost(tempComments);
    getCommentsByPostHandler(postId);
  };

  useEffect(() => {
    getAllPostsHandler();
    return () => getAllPostsHandler();
  }, [getAllPostsHandler]);

  const token = localStorage.getItem("auth_secret");
  useEffect(() => {
    const fetchData = async () => {
      const res = await getAuthHeaders();

      if (res?.response?.status === 401 || res?.response?.status === 502) {
        localStorage.clear();
        return (window.location.href = "/");
      }
      let secret = res.username + ":" + res.password;
      localStorage.setItem(
        "authHeaders",
        Buffer.from(secret).toString("base64")
      );
      let feedReportData = await getReportAbuseFeed();
      setReportAbuseData(feedReportData?.data?.story_post.DEFAULT);
    };
    fetchData();
    return () => fetchData();
  }, [token]);

  useEffect(() => {
    setPage((prev) => prev + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData?.uuid) {
      getStoriesHandler();
    }
  }, [getStoriesHandler, userData?.uuid]);
  const id = "test-toast";

  const handleLeftClick = () => {
    if (dataIndex) {
      getCommentsByPostHandler(playerData?.postId);
      setDataIndex((prev) => prev - 1);
      setPlayerData(feedList[dataIndex]);
      handleSetVideoUrl(`${FEED_IMG_URL}${playerData?.video?.remoteid}`);
    }
  };

  const handleRightClick = () => {
    if (dataIndex != feedList.length - 1) {
      setDataIndex((prev) => prev + 1);
      getCommentsByPostHandler(playerData?.postId);
      setPlayerData(feedList[dataIndex]);
      handleSetVideoUrl(`${FEED_IMG_URL}${playerData?.video?.remoteid}`);
    }
  };

  useEffect(() => {
    async function fetchUrlElements() {
      let urlElements = window.location.href.split("/");
      if (urlElements[4] != undefined) {
        let viewss = await singlePostView(urlElements[4]);
        if (urlElements[4]) {
          onFeedClickHandler(viewss.data);
        }
      }
    }
    fetchUrlElements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModalStatus = () => {
    setModalStatus(!modalStatus);
  };

  return (
    <>
      <div className="feed_wrapper">
        <Stories
          setStoryOpen={setStoryOpen}
          storiesList={storiesList}
          onShow={handleOpenSuggestionModal}
          handleSetVideoUrl={handleSetVideoUrl}
          showPlayer={handleShowPlayer}
          setPoster={setVideoPoster}
          setPlayerData={setPlayerData}
        />

        <div className="head">
          <h5>Posts</h5>
        </div>
        <div className="body">
          <FeedSkeleton isFeedLoading={isFeedLoading} />
          <Grid
            h="full"
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(4, 1fr)"
            gap={4}
            mt="5"
            mb="5"
            className="feed_grid_row"
          >
            {feedList.map((feed, index) => (
              <FeedList
                index={index}
                reportAbuseData={reportAbuseData}
                message={message}
                feedData={feed}
                reference={lastItemRef}
                key={index}
                onFeedLike={feedLikeHandler}
                onFeedUnlike={feedUnlikeHandler}
                onPostComment={postCommentHandler}
                onFeedShare={feedShareHandler}
                handleChange={handleChangeComment}
                onFeedClick={onFeedClickHandler}
                commentsOnPost={commentsOnPost}
                postFeedComment={postFeedComment}
                getCommentsByPostHandler={getCommentsByPostHandler}
              />
            ))}
          </Grid>
          {isLoading && <h5 className="text-center">Loading...</h5>}
        </div>
      </div>
      <div className="post_share_modal">
        <CustomModal
          size={storyOpen ? "md" : "lg"}
          show={showPlayer}
          handleClose={handleClosePlayer}
          className={storyOpen ? "story_modal" : "player_modal"}
          crossIcon={true}
          fromPlayer
        >
          {storyOpen ? (
            <>
              <StoryStatus
                playerData={playerData}
                handleModalStatus={handleModalStatus}
                videoUrl={videoUrl}
              />
              {/* <div className="player-wrapper" style={{ paddingTop: 0 }}>
              <div
                style={{
                  background: "transparent linear-gradient(0deg, #231F2000 0%, #000000 100%) 0% 0% no-repeat padding-box",
                  position: "absolute",
                  width: "100%",
                  height: "115px",
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                }}
              ></div>

              <div
                style={{
                  left: "12px",
                  position: "absolute",
                  top: "30px",
                  display: "flex",
                  justifyContent: "cneter",
                  alignItems: "center",
                  zIndex: "1",
                }}
              >
                <Avatar
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push(`/${playerData?.userName}`)}
                  size="sm"
                  name={`${playerData?.firstName} ${playerData?.lastName}`} />
                <span
                  style={{
                    cursor: "pointer",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                    marginLeft: "10px",
                  }}
                  onClick={() => history.push(`/${playerData?.userName}`)}
                >
                  @{playerData?.userName}
                </span>
              </div>

              <div
                style={{
                  position: "absolute",
                  right: "0",
                  zIndex: "1",
                  top: "35px",
                  width: "45px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <i
                  className="icon-md threedot_icon_story"
                  onClick={() => setModalStatus(!modalStatus)}
                  style={{ height: "24px", width: "24px" }}
                ></i>
              </div>

              <i
                className="icon share_story"
                style={{
                  position: "absolute",
                  bottom: "144px",
                  right: "15px",
                  width: "26px",
                  height: "26px",
                  zIndex: "1",
                }}
              ></i>
              <li className="share_icon">
                <Popover
                  isLazy
                  className="popover_share"
                  placement="top"
                  arrowSize="15"
                  boundary="none"
                >
                  <PopoverTrigger>
                    <i
                      className="icon share_story"
                      style={{
                        position: "absolute",
                        bottom: "144px",
                        right: "15px",
                        width: "26px",
                        height: "26px",
                        zIndex: "1",
                      }}
                    ></i>
                  </PopoverTrigger>
                  <PopoverContent
                    className="popover_content"
                    style={{ width: "100%", borderRadius: "17px" }}
                  >
                    <PopoverArrow style={{ borderBottomRightRadius: "4px" }} />
                    <div className="icon_list" style={{ border: "none" }}>
                      <FacebookShareButton
                        style={{ height: "16px" }}
                        url={`${COPY_BASE_URL}feed/${playerData.postId}`}
                      >
                        <i className="fab fa-facebook-f" title="facebook"></i>
                      </FacebookShareButton>
                      <TwitterShareButton
                        style={{ height: "16px" }}
                        url={`${COPY_BASE_URL}feed/${playerData.postId}`}
                      >
                        <i className="fab fa-twitter" title="twitter"></i>
                      </TwitterShareButton>
                      <LinkedinShareButton
                        style={{ height: "16px" }}
                        url={`${COPY_BASE_URL}feed/${playerData.postId}`}
                      >
                        <i className="fab fa-linkedin" title="linkedin"></i>
                      </LinkedinShareButton>
                      <EmailShareButton
                        style={{ height: "16px" }}
                        url={`${COPY_BASE_URL}feed/${playerData.postId}`}
                      >
                        {" "}
                        <i className="far fa-envelope" title="email"></i>
                      </EmailShareButton>
                      <button>
                        <i
                          // onClick={() => handleCopy(playerData)}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${COPY_BASE_URL}feed/${playerData.postId}`
                            );
                            if (!toast.isActive(id)) {
                              toast({
                                id,
                                position: "top",
                                title: "Copied",
                                status: "success",
                                duration: 3000,
                                isClosable: true,
                              });
                            }
                          } }
                          className="fas fa-link"
                          title="copy link"
                        ></i>
                      </button>
                    </div>
                  </PopoverContent>
                </Popover>
              </li>

              <video
                className="story_player"
                style={{ height: "100%", borderRadius: "12px", width: "100%" }}
                src={videoUrl}
                type="video/mp4"
                controls="hidden"
                playsInline
                controlsList="nodownload noplaybackrate"
                disablePictureInPicture
                autoPlay
              ></video>
              <div
                style={{
                  background: "transparent linear-gradient(180deg, #231F2000 0%, #000000 100%) 0% 0% no-repeat padding-box",
                  position: "absolute",
                  bottom: "0px",
                  width: "100%",
                  height: "150px",
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                }}
              ></div>
            </div> */}
            </>
          ) : (
            <div className="player_row">
              <div className="player_col left">
                {dataIndex != 1 ? (
                  <i
                    className="icon left_button"
                    onClick={() => handleLeftClick(dataIndex, playerData)}
                    style={{ position: "absolute", left: "-55px", top: "50%" }}
                  ></i>
                ) : (
                  ""
                )}
                <div className="player-wrapper" style={{ paddingTop: 0 }}>
                  {/* <img style={{borderTopLeftRadius:"16px",borderBottomLeftRadius:"16px"}} src={videoPoster} alt=""/>  */}
                  <video
                    className={` react-player`}
                    // ${videoClass?"video-zoom" : ""}
                    src={videoUrl}
                    type="video/mp4"
                    poster={videoPoster}
                    controls
                    controlsList="nodownload noplaybackrate"
                    disablePictureInPicture
                    autoPlay
                    // disableRemotePlayback
                    // onDoubleClick={() => setVideoClass(!videoClass)}
                  >
                    {" "}
                  </video>
                </div>
              </div>
              <div className="player_col right">
                {dataIndex + 1 == feedList.length ? (
                  ""
                ) : (
                  <i
                    className="icon right_button"
                    onClick={() => handleRightClick(dataIndex, playerData)}
                    style={{ position: "absolute", right: "-55px", top: "50%" }}
                  ></i>
                )}
                <div className="head">
                  <div className="media">
                    {/* <img src={DP} alt="" /> */}
                    <Avatar
                      style={{ cursor: "pointer" }}
                      onClick={() => history.push(`/${playerData?.userName}`)}
                      size="lg"
                      name={`${playerData?.firstName} ${playerData?.lastName}`}
                    />
                    <div className="media_details">
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <h5
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              history.push(`/${playerData?.userName}`)
                            }
                          >
                            {playerData.firstName} {playerData.lastName}
                          </h5>

                          <p
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              history.push(`/${playerData?.userNames}`)
                            }
                          >
                            @{playerData?.userName}
                          </p>
                        </div>
                        <div>
                          <div
                            style={{ cursor: "pointer" }}
                            className="icon threedot_icon"
                            onClick={() =>
                              isOwnPostForReport
                                ? setDeleteModal(true)
                                : setModalStatus(!modalStatus)
                            }
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="body">
                  <p className="metadata">{playerData?.metadata}</p>
                  <p className="text-conti">Contributors</p>
                  <ul>
                    {playerData?.contributorsDetails?.map((c, index) => (
                      <ContributorsDetailsInner key={index} contributor={c} />
                    ))}
                  </ul>
                </div>

                <div className="feed_bottom">
                  <ul style={{ display: "flex", alignItems: "flex-start" }}>
                    <li onClick={() => handleFeedLike(playerData)}>
                      <p>
                        <i
                          className={`icon ${
                            playerData.isLiked ? "liked heart_anim" : "like"
                          }`}
                        ></i>
                      </p>
                    </li>
                    <li>
                      <i
                        onClick={() => ref?.current?.focus()}
                        className="icon comment"
                      ></i>
                    </li>

                    <li className="share_icon">
                      <Popover
                        isLazy
                        className="popover_share"
                        placement="top"
                        arrowSize="15"
                        boundary="none"
                      >
                        <PopoverTrigger>
                          <i className="icon share"></i>
                        </PopoverTrigger>
                        <PopoverContent
                          className="popover_content"
                          style={{ width: "100%", borderRadius: "17px" }}
                        >
                          <PopoverArrow
                            style={{ borderBottomRightRadius: "4px" }}
                          />
                          <div className="icon_list" style={{ border: "none" }}>
                            <FacebookShareButton
                              style={{ height: "16px" }}
                              url={`${COPY_BASE_URL}feed/${playerData.postId}`}
                            >
                              <i
                                className="fab fa-facebook-f"
                                title="facebook"
                              ></i>
                            </FacebookShareButton>
                            <TwitterShareButton
                              style={{ height: "16px" }}
                              url={`${COPY_BASE_URL}feed/${playerData.postId}`}
                            >
                              <i className="fab fa-twitter" title="twitter"></i>
                            </TwitterShareButton>
                            <LinkedinShareButton
                              style={{ height: "16px" }}
                              url={`${COPY_BASE_URL}feed/${playerData.postId}`}
                            >
                              <i
                                className="fab fa-linkedin"
                                title="linkedin"
                              ></i>
                            </LinkedinShareButton>
                            <EmailShareButton
                              style={{ height: "16px" }}
                              url={`${COPY_BASE_URL}feed/${playerData.postId}`}
                            >
                              {" "}
                              <i className="far fa-envelope" title="email"></i>
                            </EmailShareButton>
                            <button>
                              <i
                                // onClick={() => handleCopy(playerData)}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    `${COPY_BASE_URL}feed/${playerData.postId}`
                                  );
                                  if (!toast.isActive(id)) {
                                    toast({
                                      id,
                                      position: "top",
                                      title: "Copied",
                                      status: "success",
                                      duration: 3000,
                                      isClosable: true,
                                    });
                                  }
                                }}
                                className="fas fa-link"
                                title="copy link"
                              ></i>
                            </button>
                          </div>
                        </PopoverContent>
                      </Popover>
                    </li>
                  </ul>
                  {playerData.isLiked && playerData.isLiked ? (
                    <>
                      <p className="like_comment_details  like-details">
                        Liked by
                        <strong>
                          {playerData.isLiked ? " You " : ""}
                          {playerData?.likesCount > 0 && (
                            <>
                              and {playerData?.likesCount}{" "}
                              {playerData?.likesCount > 1 ? "others" : "other"}
                            </>
                          )}
                        </strong>
                      </p>
                    </>
                  ) : (
                    <>
                      {playerData?.likesCount > 0 && (
                        <>
                          <p className="like-content">
                            {
                              // eslint-disable-next-line eqeqeq
                              playerData.likesCount == 1
                                ? `${playerData.likesCount} Like`
                                : `${playerData.likesCount} Likes`
                            }
                          </p>
                        </>
                      )}
                    </>
                  )}

                  <div className="comment_body" style={{ width: "100%" }}>
                    <ul className="comment_ul">
                      <div className="child-comment-wrap">
                        {commentsOnPost.map((comment, index) => (
                          <div key={index}>
                            <li
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Avatar
                                size="sm"
                                onClick={() => history.push(`/${comment.un}`)}
                                style={{ marginTop: "5px" }}
                                name={`${comment.fn} ${comment.ln}`}
                                mr="2"
                                mt="1"
                              />

                              <div className="comment_details">
                                <div
                                  onClick={() => history.push(`/${comment.un}`)}
                                  className="full_name"
                                >{`${comment.fn} ${comment.ln}`}</div>
                                <p
                                  onClick={() => history.push(`/${comment.un}`)}
                                  className="username"
                                >
                                  @{comment.un}
                                </p>
                                <div className="like_comment_icon">
                                  <p
                                    className="comment"
                                    style={{ fontSize: "12px", width: "90%" }}
                                    dangerouslySetInnerHTML={{
                                      __html: comment.attributedComment,
                                    }}
                                  ></p>
                                  <i
                                    className={`icon  ${
                                      comment?.isLiked
                                        ? "liked heart_anim"
                                        : "like"
                                    } comment_like`}
                                    style={{ height: "14px", width: "15px" }}
                                    onClick={() =>
                                      handleCommentLike(comment, comment.postId)
                                    }
                                  />
                                </div>
                                <div className="ct_time_like_count">
                                  <div> {timeGenerator(comment?.ct)}</div>
                                  {
                                    // eslint-disable-next-line eqeqeq
                                    <div>
                                      {comment.likesCount == 0
                                        ? ""
                                        : `${parseInt(
                                            comment?.likesCount
                                          )} likes`}
                                    </div>
                                  }

                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleReply(comment)}
                                  >
                                    Reply
                                  </div>
                                </div>
                              </div>
                            </li>
                            {comment?.reply.map((cmtReply, index) => (
                              <li
                                key={index}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  marginLeft: "33px",
                                }}
                              >
                                <Avatar
                                  size="xs"
                                  // round={true}
                                  onClick={() =>
                                    history.push(`/${cmtReply.un}`)
                                  }
                                  style={{ marginTop: "6px" }}
                                  name={`${cmtReply?.fn} ${cmtReply?.ln}`}
                                  mr="2"
                                  mt="1"
                                />
                                <div className="comment_details">
                                  <div
                                    onClick={() =>
                                      history.push(`/${cmtReply.un}`)
                                    }
                                    className="full_name"
                                    style={{ fontSize: "12px" }}
                                  >{`${cmtReply?.fn} ${cmtReply?.ln}`}</div>
                                  <p
                                    onClick={() =>
                                      history.push(`/${cmtReply.un}`)
                                    }
                                    className="username"
                                    style={{ fontSize: "12px" }}
                                  >
                                    @{cmtReply?.un}
                                  </p>
                                  <div className="like_comment_icon">
                                    <p
                                      className="comment"
                                      style={{ fontSize: "10px", width: "88%" }}
                                    >
                                      {cmtReply?.cmt}
                                    </p>
                                    <i
                                      className={`icon ${
                                        cmtReply.isLiked
                                          ? "liked heart_anim"
                                          : "like"
                                      } comment_like`}
                                      style={{ height: "14px", width: "15px" }}
                                      onClick={() =>
                                        handleCommentLike(
                                          cmtReply,
                                          comment.postId,
                                          true
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className="ct_time_like_count"
                                    style={{ fontSize: "10px" }}
                                  >
                                    <div> {timeGenerator(cmtReply?.ct)}</div>
                                    {
                                      // eslint-disable-next-line eqeqeq
                                      <div>
                                        {cmtReply.likesCount == 0
                                          ? ""
                                          : `${parseInt(
                                              cmtReply?.likesCount
                                            )} likes`}
                                      </div>
                                    }
                                    <div onClick={() => handleReply(comment)}>
                                      Reply
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </div>
                        ))}
                      </div>
                    </ul>
                  </div>

                  {canComment ? (
                    <CustomInput
                      comment={comment}
                      setComment={setComment}
                      userProfileData={userProfileData}
                      setIsReplying={setIsReplying}
                      setCommentForReply={setCommentForReply}
                      commentForReply={commentForReply}
                      getCommentsByPostHandler={getCommentsByPostHandler}
                      refer={ref}
                      postFeedComment={postFeedComment}
                      isReplying={isReplying}
                      playerData={playerData}
                      postCommentFromFeed={postCommentFromFeed}
                    />
                  ) : (
                    <p className="user_disable_comment">
                      User has disabled commenting at this time
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        </CustomModal>
      </div>

      <CustomModal
        show={
          (poplarList?.length > 0 || peopleYouMayKnowList?.length > 0) &&
          showSuggestionModal
        }
        handleClose={handleCloseSuggestionModal}
        className="user_list_modal"
        title="Suggestions"
        modalBodyStyle={{ padding: "0.2rem 1rem" }}
      >
        <Suggestions
          popularList={poplarList}
          peopleYouMayKnowList={peopleYouMayKnowList}
          unfollowUserHandler={unfollowUserHandler}
          followUserHandler={followUserHandler}
          getPopularAndPeopleYouMayKnowHanlder={
            getPopularAndPeopleYouMayKnowHanlder
          }
        />
      </CustomModal>

      <ReportModalPage
        handleModalStatus={handleModalStatus}
        setDeleteModal={setDeleteModal}
        deleteModal={deleteModal}
        isOwnPostForReport={isOwnPostForReport}
        fromFeed
        setModalStatus={setModalStatus}
        modalStatus={modalStatus}
        postId={playerData.postId}
        reportAbuseData={reportAbuseData}
      />
    </>
  );
};

const MemoFeed = React.memo(Feed);

export default MemoFeed;
