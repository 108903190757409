import React, { useCallback, useState, useEffect } from "react";
import Picker from "emoji-picker-react";
import JSEMOJI from "emoji-js";
import { FormControl, InputGroup } from "react-bootstrap";
import { Avatar, Button } from "@chakra-ui/react";
import { mentionPerson, postComment, replyComment } from "./Service";
import MentionList from "./mentionList";
import { v4 as uuidv4 } from "uuid";
import { useUser } from "../../context/UserProvider";
import AvatarImage from "../../components/shared/AvatarImage";

const CustomInput = ({
  redirectToPostLogin,
  sharePost,
  refer,
  notLogin,
  setIsReplying,
  commentForReply,
  getCommentsByPostHandler,
  playerData,
  isReplying,
  comment,
  setComment,
  userProfileData,
  EmojiDisable,
  isDisabled,
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [mention, setMention] = useState([]);
  const { userData } = useUser();

  const handleMention = (value) => {
    setMention((prev) => [...prev, value]);
  };
  var value = comment;
  const mentionPersonHandler = useCallback(async () => {
    let index = value.lastIndexOf("@");
    const slicedValue = value.slice(index + 1);
    if (slicedValue.length) {
      var res = await mentionPerson(slicedValue);
      setProfiles(res.profiles);
    }
  }, [value]);

  if (value.includes("@")) {
    mentionPersonHandler();
    var value = "";
  }

  const jsemoji = new JSEMOJI();

  jsemoji.img_set = "emojione";
  jsemoji.supports_css = false;
  jsemoji.allow_native = true;
  jsemoji.replace_mode = "unified";

  const onEmojiClick = (n, e) => {
    let emoji = jsemoji.replace_colons(`${e.emoji}`);
    setComment(comment + emoji);
  };

  const commentOnPostHandler = async (e, postId) => {
    e.preventDefault();
    setComment("");
    const payload = {
      postId,
      comment: comment,
      mentions: mention,
    };
    await postComment(payload);
    getCommentsByPostHandler(postId);
  };

  const replyFeedComment = async (e) => {
    e.preventDefault();

    setIsReplying(false);

    const { id, postId, uuid } = commentForReply;

    const payload = {
      comment: comment,
      commentId: id,
      mentions: [userProfileData.uuid],
      postId: postId,
      postUserId: uuid,
    };

    await replyComment(payload);

    getCommentsByPostHandler(postId);
    setComment("");
  };

  useEffect(() => {
    const closePicker = (e) => {
      var path = e.path || (e.composedPath && e.composedPath());
      if (path[0]?.tagName !== "I") {
        setShowPicker(false);
      }
    };
    document.addEventListener("click", closePicker);
    return () => document.removeEventListener("click", closePicker);
  }, []);

  const handleInnerPostComment = (e) => {
    setComment(e.target.value);
  };

  return (
    <form
      className="custom_input"
      style={{ padding: "0px 10px 10px 10px" }}
      onSubmit={(e) =>
        notLogin
          ? redirectToPostLogin
          : isReplying
          ? replyFeedComment(e, playerData)
          : commentOnPostHandler(e, playerData.postId)
      }
    >
      {comment && comment.includes("@") && (
        <MentionList
          mention={mention}
          handleMention={handleMention}
          key={uuidv4()}
          profiles={profiles}
          postFeedComment={comment}
          setPostFeedComment={setComment}
        />
      )}

      <InputGroup>
        <InputGroup.Text>
          <AvatarImage sharePost size="sm" />
        </InputGroup.Text>

        <FormControl
          disabled={isDisabled}
          ref={refer}
          placeholder="Add a comment..."
          value={comment}
          onChange={handleInnerPostComment}
        />
        <InputGroup.Text>
          {!notLogin && (
            <Button isDisabled={!comment} type="submit">
              Post
            </Button>
          )}
          {notLogin && (
            <Button isDisabled={!comment} onClick={redirectToPostLogin}>
              Post
            </Button>
          )}
          {showPicker && (
            <Picker
              onEmojiClick={onEmojiClick}
              disableSearchBar
              disableSkinTonePicker
              pickerStyle={{
                position: "absolute",
                bottom: "45px",
                width: "80%",
                right: "0",
              }}
            />
          )}
          <i
            onClick={() =>
              !isDisabled && !EmojiDisable && setShowPicker(!showPicker)
            }
            className="bi bi-emoji-smile"
          ></i>
        </InputGroup.Text>
      </InputGroup>
    </form>
  );
};

export default CustomInput;
