import { useState } from "react";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { CustomModal } from "./components/UI/Modal";
import { Button } from "@material-ui/core";

function App() {
  const [show, setShow] = useState(false);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (
        window.location.href.split("/")[3] !== "" &&
        parseInt(error.response?.status) === 401
      ) {
        setShow(true);
      } else {
        return Promise.reject(error);
      }
    }
  );
  const handleLogout = () => {
    setShow(false);
    localStorage.clear();
    window.location = "/";
  };

  return (
    <>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <CustomModal
        className="logoutButton"
        show={show}
        handleClose={setShow}
        title="Logging Out !!!"
      >
        You are logging with another device
        <p style={{ display: "flex", justifyContent: "end" }}>
          <Button
            style={{
              background: "white",
              color: "Red",
              right: "0",
            }}
            onClick={handleLogout}
          >
            OK
          </Button>
        </p>
      </CustomModal>
    </>
  );
}

export default App;
