export const TYPE = {
  fcm: "fcm",
  sms: "sms",
};

export const FEED_IMG_URL = `${
  process.env.REACT_APP_API_URL.includes("https://cachyserverdev.cachy.com")
    ? "https://storage.googleapis.com/cachy-dev-bucket/"
    : process.env.REACT_APP_API_URL.includes("https://cachyserverqa.cachy.com")
    ? "https://dqjesdd0w1dnq.cloudfront.net/"
    : "https://storage.googleapis.com/cachy-prod-store/"
}`;

export const PROFILE_IMG_URL = `${
  process.env.REACT_APP_API_URL.includes("https://cachyserverdev.cachy.com")
    ? "https://dqjesdd0w1dnq.cloudfront.net/"
    : process.env.REACT_APP_API_URL.includes("https://cachyserverqa.cachy.com")
    ? "https://dqjesdd0w1dnq.cloudfront.net/"
    : "https://d1z1jho0zp22io.cloudfront.net/"
}`;

export const APP_STORE_LINK =
  "https://apps.apple.com/in/app/cachy-social/id6443464123";
export const PLAY_STORE_LINK =
  "https://play.google.com/store/apps/details?id=com.cachysocial";

export const BASE_URL_2 = `${
  process.env.REACT_APP_API_URL.includes("https://cachyserverdev.cachy.com")
    ? "https://socialdev.cachy.com"
    : process.env.REACT_APP_API_URL.includes("https://cachyserverqa.cachy.com")
    ? "https://socialqa.cachy.com"
    : "https://social.cachy.com"
}`;
export const BASE_URL_3 = `${
  process.env.REACT_APP_API_URL.includes("https://cachyserverdev.cachy.com")
    ? "https://userserverdev.cachy.com"
    : process.env.REACT_APP_API_URL.includes("https://cachyserverqa.cachy.com")
    ? "https://userserverqa.cachy.com"
    : "https://userserver.cachy.com"
}`;
export const BASE_URL_4 = `${
  process.env.REACT_APP_API_URL.includes("https://cachyserverdev.cachy.com")
    ? "https://admindev.cachy.com"
    : process.env.REACT_APP_API_URL.includes("https://cachyserverqa.cachy.com")
    ? "https://adminqa.cachy.com"
    : "https://admin.cachy.com"
}`;
export const SOCKET_SERVER_URL = "https://websocket.cachy.com";
export const COPY_BASE_URL = `${
  process.env.REACT_APP_API_URL.includes("https://cachyserverdev.cachy.com")
    ? "https://dev.cachy.com/"
    : process.env.REACT_APP_API_URL.includes("https://cachyserverqa.cachy.com")
    ? "https://qa.cachy.com/"
    : "https://cachy.com/"
}`;
