import axios from "axios";
import { BASE_URL_2, BASE_URL_3 } from "../../utils/config";
let config = {
  baseURL: BASE_URL_2,

  headers: {
    Authorization: `Basic ${localStorage.getItem("authHeaders")}`,
  },
};
let newAxios = axios.create(config);

export const getPostByUUID = async (uuid) => {
  try {
    const { data } = await axios.get(`/cachy/v1/posts/${uuid}`);
    return data;
  } catch (err) {
    return err;
  }
};

export const getAllPosts = async (page) => {
  try {
    const { data } = await axios.get(`cachy/v1/postwall?page=${page}`);
    return data;
  } catch (err) {
    return err;
  }
};
export const getStories = async () => {
  try {
    const { data } = await axios.get(`/cachy/v1/storywall`);
    return data;
  } catch (err) {
    return err;
  }
};

export const commentLike = async (payload) => {
  let configs = {
    baseURL: BASE_URL_2,
    headers: {
      Authorization: `Basic ${localStorage.getItem("authHeaders")}`,
    },
  };
  let newAxioss = axios.create(configs);
  try {
    const { data } = await newAxioss.put(`cachy/v1/comment/like`, payload);
    return data;
  } catch (err) {
    return err;
  }
};

export const feedLike = async (payload) => {
  try {
    const { data } = await newAxios.put(`/cachy/v1/like`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const postComment = async (payload) => {
  let configs = {
    baseURL: BASE_URL_2,
    headers: {
      Authorization: `Basic ${localStorage.getItem("authHeaders")}`,
    },
  };
  let newAxioss = axios.create(configs);
  try {
    const { data } = await newAxioss.put(`/cachy/v1/comment`, payload);
    return data;
  } catch (err) {
    return err.response;
  }
};

export const replyComment = async (payload) => {
  let config = {
    baseURL: BASE_URL_2,

    headers: {
      Authorization: `Basic ${localStorage.getItem("authHeaders")}`,
    },
  };
  let newAxios = axios.create(config);
  try {
    const { data } = await newAxios.put(`/cachy/v1/reply`, payload);
    return data;
  } catch (err) {
    return err;
  }
};

export const replyCommentLike = async (payload) => {
  try {
    const { data } = await newAxios.put(`/cachy/v1/comment/like`, payload);
    return data;
  } catch (err) {
    return err;
  }
};

export const feedShare = async (payload) => {
  try {
    const { data } = await newAxios.put(`/cachy/v1/post/share`, payload);
    return data;
  } catch (err) {
    return err;
  }
};
export const getCommentsByPost = async (postId) => {
  try {
    const { data } = await axios.get(`/cachy/v1/comment/${postId}`);
    return data;
  } catch (err) {
    return err;
  }
};
// need to auth token
export const viewsOnPost = async (postId) => {
  try {
    const { data } = await axios.get(`/cachy/v1/views/${postId}?page=1`);
    return data;
  } catch (err) {
    return err;
  }
};

// no need any auth token
export const onePostView = async (postId) => {
  try {
    const { data } = await axios.get(`/cachy/v1/post/viewpost/${postId}`);
    return data;
  } catch (error) {
    return error;
  }
};

export const singlePostView = async (postId) => {
  try {
    const { data } = await axios.get(`/cachy/v1/post/view/${postId}?page=1`);
    return data;
  } catch (error) {
    return error;
  }
};

export const getReportAbuseFeed = async (postId) => {
  let configs = {
    baseURL: BASE_URL_3,
    headers: {
      Authorization: `Basic ${localStorage.getItem("authHeaders")}`,
    },
  };
  let newAxioss = axios.create(configs);

  try {
    const { data } = await newAxioss.get(
      `${BASE_URL_3}/cachy/v1/reportAbuse?type=story_post`
    );
    return data;
  } catch (error) {
    return { code: 500 };
  }
};

export const postReportAbuse = async (payload) => {
  try {
    const { data } = await axios.put(`/cachy/reportPost`, payload);
    return data;
  } catch (error) {
    return { code: 422, message: "Already reported. " };
  }
};

export const getStoryList = async (uuid) => {
  try {
    const { data } = await axios.get(`/v1/story/${uuid}`);
    return data;
  } catch (err) {
    return err;
  }
};

export const mentionPerson = async (payload) => {
  try {
    const { data } = await axios.get(`cachy/v1/profile/mention?key=${payload}`);
    return data;
  } catch (error) {
    return error;
  }
};
export const deletePost = async (postId) => {
  try {
    const { data } = await axios.delete(`/cachy/v1/post/${postId}`);
    return data;
  } catch (error) {
    return { code: 422, message: "Already reported. " };
  }
};

export const getPartialProfile = async (uuid) => {
  try {
    const { data } = await axios.get(`cachy/v1/profile/partial/${uuid}`);
    return data;
  } catch (error) {
    return error;
  }
};

export const addViewToFeed = async (payload) => {
  try {
    const { data } = await newAxios.put(`cachy/v1/post/addView`, payload);
    return data;
  } catch (error) {
    return error;
  }
};
