import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Footer } from "../../components/shared/Footer";
import PublicNavbar from "../../components/shared/PublicNavbar";
import { getPublicPageContent } from "../../publicApiCalls/Service";
import PublicPageSkeleton from "../../components/Skeleton/PublicPage";
import isEmpty from "is-empty";

export default function Privacy() {
  const [contentData, setContentData] = useState({});
  const getPrivacyPolicyContentHanlder = async () => {
    const res = await getPublicPageContent("privacy-policy");
    if (res.code === 200) {
      setContentData(res.data);
    }
  };

  useEffect(() => {
    getPrivacyPolicyContentHanlder();
  }, []);

  return (
    <>
      <PublicNavbar />
      <div className="public_wrapper">
        <p style={{ textAlign: "center", marginTop: "45px" }}>Privacy Policy</p>
        <div className="body">
          <div className="privacy_page">
            {!isEmpty(contentData?.content) ? (
              <Container>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${contentData.content}`,
                  }}
                />
              </Container>
            ) : (
              <PublicPageSkeleton />
            )}
          </div>
        </div>
      </div>
      <Footer extraItems />
    </>
  );
}
