import PublicNavbar from "../../components/shared/PublicNavbar";
import { Footer } from "../../components/shared/Footer";
import { Container } from "react-bootstrap";
import { Box } from "@chakra-ui/react";
import Android from "../../assets/img/icons/android_icon.svg";
import Ios from "../../assets/img/icons/ios_icon.svg";
import { APP_STORE_LINK, PLAY_STORE_LINK } from "../../utils/config";
import { useEffect } from "react";
import { isAndroid, isIOS } from "react-device-detect";

const DownloadApp = () => {
  useEffect(() => {
    if (isAndroid) {
      window.location.href = PLAY_STORE_LINK;
    } else if (isIOS) {
      window.location.href = APP_STORE_LINK;
    }
  }, []);
  return (
    <div className="main_download">
      <PublicNavbar />
      <div
        className="public_wrapper help jobs_wrapper"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <Container className="download_container">
            <Box className="download_box">
              <p className="download_cong_text">
                Congratulations! You got early access ;)
              </p>

              <p className="download_ios_and">Download for Android/iOS.</p>
              <div className="download_buttons">
                <div>
                  <a href={PLAY_STORE_LINK} target="_blank" rel="noreferrer">
                    <img src={Android} alt="Download Android App" />
                  </a>
                </div>
                <a href={`${APP_STORE_LINK}`} target="_blank" rel="noreferrer">
                  <img src={Ios} alt="Download iOS App" />
                </a>
              </div>
            </Box>
          </Container>
        </div>
      </div>
      <Footer extraItems />
    </div>
  );
};

export default DownloadApp;
