// import React, { useState } from "react";
// import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { CustomModal } from "../../components/UI/Modal";

export default function HelpAndSupport() {
  // const [showDeleteModal, setShowDeleteModal] = useState(false);

  // const handleCloseModal = () => setShowDeleteModal(false);
  // const handleOpenModal = () => setShowDeleteModal(true);

  return (
    <>
      <div className="help_support_wrap">
        <h5 className="title on_web">Help and Support</h5>
        <ul>
          <li>
            <Link to="/help" target="_blank">
              FAQ
            </Link>
          </li>
          {/* <li>
            <Link to="">Account</Link>
          </li> */}
        </ul>
        {/* <p className="delete" onClick={handleOpenModal}>
          Delete My Account
        </p> */}
      </div>
      {/* <CustomModal
        show={showDeleteModal}
        handleClose={handleCloseModal}
        className="delete_account_modal"
        size="sm"
      >
        <span className="icon_wrap">
          <i className="icon delete"></i>
        </span>

        <p>
          Are you sure you want delete your Account ? This will permanently
          delete your account and data.
        </p>
        <div className="buttons">
          Cancel
          <Button type="submit">Delete</Button>
        </div>
      </CustomModal> */}
    </>
  );
}
