import React, { useState, useEffect, useCallback } from "react";
import { onePostView } from "../Feed/Service";
import { useParams, useHistory } from "react-router-dom";

import { COPY_BASE_URL, FEED_IMG_URL } from "../../utils/config";
import { Avatar, useToast } from "@chakra-ui/react";
import PublicNavbar from "../../components/shared/PublicNavbar";
import LoginModal from "../LoginModal/LoginModal";
import CustomInput from "../Feed/CustomInput";
import PostUserLoginModal from "../PostUserLoginModal/PostUserLoginModal";
import ContributorsDetailsInner from "../Feed/ContributorsDetailsInner";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";

// import { Helmet } from "react-helmet";
// import previewImg from "../../assets/img/previewImg.svg";
import BrokenPage from "../../components/shared/BrokenPage";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import { handleProfile } from "../Feed/ProfileImageDecryption";
import SharePostLogin from "../LoginModal/SharePostLogin";

const SharePost = () => {
  const history = useHistory();
  const { postId } = useParams();
  const toast = useToast();
  // const { onSubmitCommentsOnPost, commentsOnPost } = useFeed();
  const [submissionModal, setsubmissionModal] = useState(false);
  const [submissionValue, setsubmissionValue] = useState(false);
  const [resdata, setResData] = useState();
  // eslint-disable-next-line no-unused-vars
  const [videoPoster, setVideoPoster] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [postLoginValueModal, setpostLoginValueModal] = useState(false);
  const [comment, setComment] = useState("");
  const [broken, setBroken] = useState(false);
  const [postPrivate, setPostPrivate] = useState(false);
  const [classValue, setClassValue] = useState("");
  const [mainHeading, setMainHeading] = useState("");

  const [image, setImage] = useState("");

  const handleCloseSubmissionModal = useCallback(async () => {
    setsubmissionModal(false);
    setpostLoginValueModal(false);
  }, []);

  const redirectToLogin = (heading, classValue) => {
    setMainHeading(heading);
    setClassValue(classValue);
    setsubmissionModal(true);
    // setsubmissionValue(value);
  };

  const redirectToPostLogin = () => {
    setpostLoginValueModal(true);
  };

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    async function fetchData() {
      const res = await onePostView(`${postId}`);

      if (res.toString().includes(4)) {
        if (res.response.status === (452 || 430)) {
          setBroken(true);
          return;
        }
        if (res.response.status === 404) {
          return console.log("this is not valid url");
        }
      }
      setResData(res);
      if (res?.data?.isPrivate) {
        setPostPrivate(true);
        return;
      }

      if (res.data.avtaar && res.data.profileKey) {
        const res1 = await handleProfile(res.data.avtaar, res.data.profileKey);
        setImage(res1);
      }
      setVideoPoster(`${FEED_IMG_URL}${res?.data?.coverImg?.remoteid}`);
      setVideoUrl(`${FEED_IMG_URL}${res?.data?.video?.remoteid}`);
      document.body.style.backgroundColor = res.data
        ? "#171D17D9"
        : "linear-gradient(180deg, #FFFAED 0%, #FFFDF8 39%, #FFFEFC 70%, #FFFFFF 100%)";
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const id = "test-toast";

  const CustomButton = () => {
    return <button>Follow</button>;
  };

  return (
    <>
      {broken ? (
        <BrokenPage
          heading_1="This content isn’t available at the moment"
          heading_2="Try Viewing it on Cachy mobile."
        />
      ) : (
        <>
          {postPrivate ? (
            <SharePostLogin />
          ) : (
            <>
              <div
                className="post_share_modal share-post-wrap"
                // onClick={handleClick}
                id="parent"
              >
                <div className="share-post-container">
                  <PublicNavbar />
                  <div
                    className="player_modal share_post_size"
                    style={{ height: `${isSafari ? "802px" : "791px"}` }}
                  >
                    <div className="player_row">
                      <div className="player_col left">
                        <div className="player-wrapper">
                          <video
                            className="react-player"
                            style={{ height: `${isSafari ? "none" : "100%"}` }}
                            autoPlay
                            playsInline
                            controls
                            src={videoUrl}
                            poster={videoPoster}
                          ></video>
                        </div>
                      </div>
                      <div className="player_col right">
                        <div
                          onClick={() =>
                            redirectToLogin(
                              "Follow the Creators on Cachy Mobile",
                              "follow_share"
                            )
                          }
                          className="head"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            size="lg"
                            style={{
                              cursor: "pointer",
                              background: `${image ? "none" : ""}`,
                            }}
                            src={image}
                            name={
                              resdata?.data?.firstName +
                              " " +
                              resdata?.data?.lastName
                            }
                          />
                          <div className="media_details">
                            <h2>
                              {resdata?.data?.firstName}{" "}
                              {resdata?.data?.lastName}
                            </h2>
                            <p>@{resdata?.data?.userName}</p>
                          </div>
                        </div>

                        <div className="body">
                          <p className="metadata">{resdata?.data?.metadata}</p>
                          <p className="text-conti">Contributors</p>
                          <ul
                            onClick={() =>
                              redirectToLogin(
                                "Follow the Creators on Cachy Mobile",
                                "follow_share"
                              )
                            }
                          >
                            {resdata?.data?.contributorsDetails?.map(
                              (c, index) => (
                                <ContributorsDetailsInner
                                  key={index}
                                  contributor={c}
                                />
                              )
                            )}
                          </ul>
                        </div>
                        <div className="feed_bottom">
                          <ul>
                            <i
                              className={`icon  ${
                                resdata?.data?.isLiked
                                  ? "liked heart_anim"
                                  : "like"
                              }`}
                              onClick={() => {
                                redirectToLogin(
                                  "Share the Love via Cachy Mobile.",
                                  "login_to_like"
                                );
                              }}
                            ></i>

                            <i
                              className="icon comment"
                              onClick={() => {
                                redirectToLogin(
                                  "Join the Conversation via Cachy Mobile.",
                                  "comment_share"
                                );
                              }}
                            ></i>

                            <Popover
                              isLazy
                              className="popover_share"
                              placement="top"
                              arrowSize="15"
                              boundary="none"
                            >
                              <PopoverTrigger>
                                <i className="icon share"></i>
                              </PopoverTrigger>
                              <PopoverContent
                                className="popover_content"
                                style={{
                                  width: "100%",
                                  borderRadius: "30px",
                                  boxShadow: "none",
                                }}
                              >
                                <PopoverArrow
                                  style={{ borderBottomRightRadius: "4px" }}
                                />
                                <div
                                  className="icon_list"
                                  style={{ border: "none", padding: "8px" }}
                                >
                                  <FacebookShareButton
                                    style={{
                                      height: "16px",
                                      padding: "0.3rem",
                                    }}
                                    url={`${COPY_BASE_URL}feed/${postId}`}
                                  >
                                    {/* <i className="icon_sm facebook_md_share"></i> */}
                                    <i
                                      className="fab fa-facebook-f"
                                      title="facebook"
                                    ></i>
                                  </FacebookShareButton>
                                  <TwitterShareButton
                                    style={{
                                      height: "16px",
                                      padding: "0.3rem",
                                    }}
                                    url={`${COPY_BASE_URL}feed/${postId}`}
                                  >
                                    <i
                                      className="fab fa-twitter"
                                      title="twitter"
                                    ></i>
                                  </TwitterShareButton>
                                  <LinkedinShareButton
                                    style={{
                                      height: "16px",
                                      padding: "0.3rem",
                                    }}
                                    url={`${COPY_BASE_URL}feed/${postId}`}
                                  >
                                    <i className="fab fa-linkedin"></i>
                                  </LinkedinShareButton>
                                  <EmailShareButton
                                    style={{
                                      height: "16px",
                                      padding: "0.3rem",
                                    }}
                                    url={`${COPY_BASE_URL}feed/${postId}`}
                                  >
                                    {" "}
                                    <i className="far fa-envelope"></i>{" "}
                                  </EmailShareButton>
                                  <button>
                                    <i
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          `${COPY_BASE_URL}feed/${postId}`
                                        );
                                        if (!toast.isActive(id)) {
                                          toast({
                                            id,
                                            position: "top",
                                            title: "Copied",
                                            status: "success",
                                            duration: 3000,
                                            isClosable: true,
                                          });
                                        }
                                      }}
                                      style={{
                                        padding: "0.3rem",
                                      }}
                                      className="fas fa-link"
                                    ></i>
                                  </button>
                                </div>
                              </PopoverContent>
                            </Popover>
                          </ul>
                          <p className="like_comment_details">
                            {resdata?.data?.isLiked ? (
                              <>
                                Liked by
                                <strong>
                                  {resdata?.data?.isLiked ? " You " : ""}
                                  {resdata?.data?.likesCount > 0 && (
                                    <>
                                      and {resdata?.data?.likesCount}{" "}
                                      {resdata?.data?.likesCount > 1
                                        ? "others"
                                        : "other"}
                                    </>
                                  )}
                                </strong>
                              </>
                            ) : (
                              <>
                                {resdata?.data?.likesCount > 0 && (
                                  <>
                                    <p>
                                      {
                                        // eslint-disable-next-line eqeqeq
                                        resdata?.data?.likesCount == 1
                                          ? `${resdata?.data?.likesCount} Like`
                                          : `${resdata?.data?.likesCount} Likes`
                                      }
                                    </p>
                                  </>
                                )}
                              </>
                            )}
                          </p>
                          {resdata?.data?.commentsCount ? (
                            <p
                              onClick={() => {
                                redirectToLogin(
                                  "Join the Conversation via Cachy Mobile.",
                                  "comment_share"
                                );
                              }}
                              className="view-all-comment"
                            >
                              {`View all  ${resdata?.data?.commentsCount}  comments`}
                            </p>
                          ) : (
                            ""
                          )}

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                            className="share-post-comment-wrap"
                          >
                            <div
                              className="comment_body"
                              style={{
                                height: "25.7rem",
                                width: "100%",
                              }}
                            >
                              <ul className="comment_ul"></ul>
                            </div>
                            <div
                              onClick={() => {
                                redirectToLogin(
                                  "Join the Conversation via Cachy Mobile.",
                                  "comment_share"
                                );
                              }}
                            >
                              <CustomInput
                                redirectToPostLogin={redirectToPostLogin}
                                sharePost
                                comment={comment}
                                setComment={setComment}
                                playerData={resdata?.data}
                                notLogin
                                isDisabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <LoginModal
                handleClose={handleCloseSubmissionModal}
                show={submissionModal}
                value={submissionValue}
                classValue={classValue}
                mainHeading={mainHeading}
              ></LoginModal>

              <PostUserLoginModal
                handleClose={handleCloseSubmissionModal}
                show={postLoginValueModal}
              ></PostUserLoginModal>
            </>
          )}
        </>
      )}
    </>
  );
};

export default SharePost;
