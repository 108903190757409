import { Avatar } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useUser } from "../../context/UserProvider";
import { handleProfile } from "../../pages/Feed/ProfileImageDecryption";

const AvatarImage = ({ size, sharePost }) => {
  const [image, setImage] = useState("");

  const { userData, userProfileData } = useUser();

  useEffect(() => {
    const ProfileImage = async () => {
      try {
        if (userProfileData?.avatar && userData?.profileKey) {
          const res = await handleProfile(
            userProfileData.avatar,
            userData.profileKey
          );
          setImage(res);
        }
      } catch (error) {
        return error;
      }
    };
    ProfileImage();
  }, []);
  return (
    <Avatar
      style={{ background: `${image.length > 0 && "none"}` }}
      size={size}
      src={image}
      name={
        !sharePost &&
        `${userProfileData?.firstName} ${userProfileData?.lastName}`
      }
    />
  );
};

export default AvatarImage;
