/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Text, useToast } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";

import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AvatarImage from "../../components/shared/AvatarImage";
import { CustomModal } from "../../components/UI/Modal";
import { useTags } from "../../context/TagsProvider";
import { useUser } from "../../context/UserProvider";
import AddTags from "./AddTags";
import { getWalletBalance } from "./Service";
// import { useUser } from "../../context/UserProvider";

import { getUserProfileByUsername, updateProfile } from "./Service";

export default function EditProfile() {
  const { username } = useParams();
  const { userData, userProfileData, getUserProfileHandler } = useUser();
  const {
    allProfessionalTags,
    selectedTagOne,
    selectedTagTwo,
    tagType,
    setSelectedTagOne,
    setSelectedTagTwo,
    setTagType,
    getProfessionalTagsHandler,
    setAllProfessionalTags,
  } = useTags();
  const [userDetails, setUserDetails] = useState({});
  const [userDetailsToEdit, setUserDetailsToEdit] = useState({
    firstName: null,
    lastName: null,
    bio: null,
  });

  const [onShowTagModalOne, setShowTagModalOne] = useState(false);
  const [onShowTagModalTwo, setShowTagModalTwo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState();
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState();
  const toast = useToast();

  const handleCloseTagModalOne = () => setShowTagModalOne(false);
  const handleOpenTagModalOne = (type) => {
    setTagType(type);
    setShowTagModalOne(true);
  };
  const handleCloseTagModalTwo = () => setShowTagModalTwo(false);
  const handleOpenTagModalTwo = (type) => {
    setTagType(type);
    setShowTagModalTwo(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetailsToEdit((prev) => ({ ...prev, [name]: value }));
  };

  const getUserProfileByIdHandler = useCallback(async () => {
    const res = await getUserProfileByUsername(username);
    setUserDetails(res);
  }, [username]);

  useEffect(() => {
    if (userDetails?.tags?.length > 0) {
      setSelectedTagOne(userDetails.tags.filter((p) => p.type === 1));
      setSelectedTagTwo(userDetails.tags.filter((p) => p.type === 2));
    }
  }, [setSelectedTagOne, setSelectedTagTwo, userDetails.tags]);

  useEffect(() => {
    if (userData?.profile?.username !== username) {
      getUserProfileByIdHandler();
    } else {
      setUserDetails(userProfileData);
    }
  }, [
    getUserProfileByIdHandler,
    userData?.profile?.username,
    username,
    userProfileData,
  ]);

  const id = "test-toast";
  const updateProfileHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    const { firstName, lastName, bio } = userDetailsToEdit;
    const tags = [...selectedTagOne, ...selectedTagTwo];

    const payload = {
      firstName,
      lastName,
      bio,
      language1: "en",
      language2: "ar",
      tags: tags,
      instagram: null,
      twitter: null,
    };

    // eslint-disable-next-line eqeqeq
    if (firstName == undefined || null) {
      payload.firstName = userDetails.firstName;
    }

    // eslint-disable-next-line eqeqeq
    if (lastName == undefined || null) {
      payload.lastName = userDetails.lastName;
    }

    const res = await updateProfile(payload);
    setError(res?.data?.code != 424);
    if (
      (res?.data?.code != 423) &
      (res?.data?.code != 427) &
      (res?.data?.code != 424)
      // validated !== true
    ) {
      if (!toast.isActive(id)) {
        toast({
          id,
          title: "Profile Update Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Error",
        description: res?.data?.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    const userProf = localStorage.getItem("userProfileData");

    const profileData = JSON.parse(userProf);
    getUserProfileHandler(profileData.uuid);

    setIsLoading(false);
  };

  const handleTagSelectOne = (tag) => {
    tag.type = tagType;
    const tempData = [...allProfessionalTags];

    tempData.forEach((el) => {
      if (el.id === tag.id) {
        el.isChecked = true;
      }
    });
    setAllProfessionalTags(tempData);
    setSelectedTagOne([tag]);
  };
  const handleTagSelectTwo = (tag) => {
    tag.type = tagType;
    const tempData = [...allProfessionalTags];

    tempData.forEach((el) => {
      if (el.id === tag.id) {
        el.isChecked = true;
      }
    });

    setAllProfessionalTags(tempData);
    setSelectedTagTwo([tag]);
  };

  const getWalletBalanceHandler = async () => {
    const res = await getWalletBalance();
    setIsEmailVerified(res.isEmailVerified);
  };

  useEffect(() => {
    getWalletBalanceHandler();
    getProfessionalTagsHandler();
  }, []);

  // const isFirstname = userDetailsToEdit?.firstName == userDetails?.firstName;

  // const isLastname = userDetailsToEdit?.lastName == userDetails?.lastName;

  // const isBio =
  //   userDetailsToEdit?.bio == null || userDetailsToEdit?.bio == userDetails.bio;

  // const isTagOne = selectedTagOne[0]?.id == userDetails?.tags[0]?.id;

  // const isTagTwo = selectedTagTwo[0]?.id == userDetails?.tags[1]?.id;

  const blockSpecialKeys = (e) => {
    var regex = new RegExp("^[a-zA-Z]+$");
    var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (!regex.test(key)) {
      e.preventDefault();
      return false;
    }
  };

  return (
    <>
      <div className="edit_profile_wrap">
        <div>
          <div className="title">Edit Account</div>
          <div className="edit_head">
            <div className="profile_dp_wrap">
              <AvatarImage size="xl" />
            </div>
            <div className="profile_details">
              <p style={{ fontSize: "24px", fontWeight: "bold" }}>
                {userDetails?.firstName} {userDetails?.lastName}
              </p>
              <p
                style={{
                  color: "#707070",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                @{userDetails?.username}
              </p>
            </div>
          </div>
        </div>
        <div className="edit_body">
          <Form
            noValidate
            validated={validated}
            onSubmit={updateProfileHandler}
          >
            <Row>
              <Col>
                {" "}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      opacity: "0.8",
                    }}
                  >
                    First Name
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      style={{ fontSize: "14px", fontWeight: "bold" }}
                      defaultValue={userDetails?.firstName}
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      onChange={handleChange}
                      required
                      onKeyPress={blockSpecialKeys}
                    />
                    {error && (
                      <Form.Control.Feedback type="invalid">
                        This field is required.
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      opacity: "0.8",
                    }}
                  >
                    Last Name
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      style={{ fontSize: "14px", fontWeight: "bold" }}
                      defaultValue={userDetails?.lastName}
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      onChange={handleChange}
                      required
                      onKeyPress={blockSpecialKeys}
                    />
                    {error && (
                      <Form.Control.Feedback type="invalid">
                        This field is required.
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label
                style={{ fontSize: "14px", fontWeight: "bold", opacity: "0.8" }}
              >
                Email address
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                  defaultValue={userDetails?.email}
                  type="email"
                  name="email"
                  onChange={handleChange}
                  placeholder="Enter email address"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  This field is required.
                </Form.Control.Feedback>
              </InputGroup>
              {!isEmailVerified ? (
                <Text
                  style={{ fontWeight: "bold" }}
                  textAlign="right"
                  fontSize="12px"
                  mt="1"
                  color="red"
                >
                  Email Not Verified
                </Text>
              ) : (
                <Text textAlign="right" fontSize="12px" mt="1" color="green">
                  Email Verified
                </Text>
              )}
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label
                style={{ fontSize: "14px", fontWeight: "bold", opacity: "0.8" }}
              >
                Bio
              </Form.Label>
              <Form.Control
                style={{ fontSize: "14px", fontWeight: "bold" }}
                onChange={handleChange}
                defaultValue={userDetails?.bio}
                name="bio"
                as="textarea"
                rows={6}
              />
            </Form.Group>
            <Row className="tags_row">
              <Col>
                <Form.Group
                  controlId="exampleForm.ControlTextarea1"
                  onClick={() => handleOpenTagModalOne(1)}
                >
                  <Form.Label
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      opacity: "0.8",
                    }}
                  >
                    Add Industry Tags
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">
                      <i className="icon tags"></i>
                    </InputGroup.Text>

                    <span
                      className="add_tag_tex"
                      style={{
                        fontSize: "14px",
                        color: "#231F20",
                        fontWeight: "bold",
                      }}
                    >
                      {selectedTagOne.length === 1
                        ? selectedTagOne[0].name
                        : " Select Industry Tags"}
                    </span>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  controlId="exampleForm.ControlTextarea1"
                  onClick={() => handleOpenTagModalTwo(2)}
                >
                  <Form.Label>&nbsp;</Form.Label>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon1">
                      <i className="icon tags"></i>
                    </InputGroup.Text>
                    <span
                      className="add_tag_tex"
                      style={{
                        fontSize: "14px",
                        color: "#231F20",
                        fontWeight: "bold",
                      }}
                    >
                      {selectedTagTwo.length === 1
                        ? selectedTagTwo[0].name
                        : " Select Industry Tags"}
                    </span>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <div className="button_wrap">
              <Button
                style={{
                  borderRadius: "30px",
                  height: "40px",
                  width: "160px",
                  backgroundColor: "#D8463D",
                }}
                isLoading={isLoading}
                loadingText="Saving"
                type="submit"
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <CustomModal
        show={onShowTagModalOne}
        handleClose={handleCloseTagModalOne}
        className="modal_sm"
        title="Add Tags"
        style={{ overflowY: "hidden" }}
      >
        <AddTags
          tagList={allProfessionalTags.filter(
            (tag) => tag.name !== selectedTagTwo[0]?.name
          )}
          onChange={handleTagSelectOne}
          onSubmit={handleCloseTagModalOne}
          selectedTagOne={selectedTagOne[0]?.name}
        />
      </CustomModal>
      <CustomModal
        show={onShowTagModalTwo}
        handleClose={handleCloseTagModalTwo}
        className="modal_sm"
        title="Add Tags"
        style={{ overflowY: "hidden" }}
      >
        <AddTags
          tagList={allProfessionalTags.filter(
            (tag) => tag.name !== selectedTagOne[0]?.name
          )}
          onChange={handleTagSelectTwo}
          onSubmit={handleCloseTagModalTwo}
          selectedTagOne={selectedTagTwo[0]?.name}
        />
      </CustomModal>
    </>
  );
}
