///cachy/v1/profile/{uuid}/{version}

import axios from "axios";

export const getUserProfile = async (id) => {
  try {
    
    const { data } = await axios.get(`cachy/v1/profile/${id}`);
    return data;
    
  } catch (err) {
    
    return err;
  }
};

export const getPopularList = async () => {
  try {
    const { data } = await axios.get(`cachy/v1/popular`);
    return data;
  } catch (err) {
    return err;
  }
};

export const peopleYouMayKnow = async () => {
  try {
    const { data } = await axios.get(`/cachy/v1/professionals?page=1`);
    return data;
  } catch (err) {
    return err;
  }
};

export const getPopularAndPeopleYouMayKnow = async () => {
  try {
    const { data } = await axios.get(`/cachy/v1/contacts`);
    return data;
  } catch (err) {
    return err;
  }
};

export const followUser = async (payload) => {
  try {
    const { data } = await axios.put(`/cachy/v1/following`, payload);
    return data;
  } catch (err) {
    return err;
  }
};

export const unfollowUser = async (payload) => {
  try {
    const { data } = await axios.put(`/cachy/v1/unfollow`, payload);
    return data;
  } catch (err) {
    return err;
  }
};

export const getAllNotifications = async () => {
  try {
    const { data } = await axios.get(
      `/cachy/v1/notification?cascade=true&web=true`
    );
    return data;
  } catch (err) {
    return err;
  }
};
