import React, { useEffect, useCallback, useState, useRef } from "react";
import {
  Avatar,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  useToast,
} from "@chakra-ui/react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share";
import { useHistory } from "react-router-dom";
import { COPY_BASE_URL } from "../../utils/config";
import ReportModalPage from "../../pages/ReportModal/ReportModal";
import { useUser } from "../../context/UserProvider";

const Storystatus = (props) => {
  const { playerData, handleModalStatus, videoUrl } = props;
  const { userData } = useUser();
  const isOwnPostForReport = userData?.uuid === playerData?.uuid;
  const [deleteModal, setDeleteModal] = useState(false);

  const history = useHistory();
  const toast = useToast();
  const id = "test-toast";
  let postIdUrl = "";

  playerData.stories?.map((item) => {
    postIdUrl = item.postId;
  });

  return (
    <>
      <div className="player-wrapper" style={{ paddingTop: 0 }}>
        <div
          style={{
            background:
              "transparent linear-gradient(0deg, #231F2000 0%, #000000 100%) 0% 0% no-repeat padding-box",
            position: "absolute",
            width: "100%",
            height: "115px",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
          }}
        ></div>

        <div
          style={{
            left: "12px",
            position: "absolute",
            top: "30px",
            display: "flex",
            justifyContent: "cneter",
            alignItems: "center",
            zIndex: "1",
          }}
        >
          <Avatar
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push(`/${playerData?.userName || playerData.username}`)
            }
            size="sm"
            name={`${playerData?.firstName} ${playerData?.lastName}`}
          />
          <span
            style={{
              cursor: "pointer",
              color: "#FFFFFF",
              fontWeight: "bold",
              marginLeft: "10px",
            }}
            onClick={() =>
              history.push(`/${playerData?.userName || playerData?.username}`)
            }
          >
            @{playerData?.userName || playerData?.username}
          </span>
        </div>

        <div
          style={{
            position: "absolute",
            right: "0",
            zIndex: "1",
            top: "35px",
            width: "45px",
            alignItems: "center",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <i
            className="icon-md threedot_icon_story"
            onClick={
              isOwnPostForReport
                ? () => setDeleteModal(!deleteModal)
                : handleModalStatus
            }
            style={{ height: "24px", width: "24px" }}
          ></i>
        </div>

        <i
          className="icon share_story"
          style={{
            position: "absolute",
            bottom: "144px",
            right: "15px",
            width: "26px",
            height: "26px",
            zIndex: "1",
          }}
        ></i>
        <li className="share_icon">
          <Popover
            isLazy
            className="popover_share"
            placement="top"
            arrowSize="15"
            boundary="none"
          >
            <PopoverTrigger>
              <i
                className="icon share_story"
                style={{
                  position: "absolute",
                  bottom: "144px",
                  right: "15px",
                  width: "26px",
                  height: "26px",
                  zIndex: "1",
                }}
              ></i>
            </PopoverTrigger>
            <PopoverContent
              className="popover_content"
              style={{ width: "100%", borderRadius: "17px" }}
            >
              <PopoverArrow style={{ borderBottomRightRadius: "4px" }} />
              <div className="icon_list" style={{ border: "none" }}>
                <FacebookShareButton
                  style={{ height: "16px" }}
                  url={`${COPY_BASE_URL}feed/${playerData.postId || postIdUrl}`}
                >
                  <i className="fab fa-facebook-f" title="facebook"></i>
                </FacebookShareButton>
                <TwitterShareButton
                  style={{ height: "16px" }}
                  url={`${COPY_BASE_URL}feed/${playerData.postId || postIdUrl}`}
                >
                  <i className="fab fa-twitter" title="twitter"></i>
                </TwitterShareButton>
                <LinkedinShareButton
                  style={{ height: "16px" }}
                  url={`${COPY_BASE_URL}feed/${playerData.postId || postIdUrl}`}
                >
                  <i className="fab fa-linkedin" title="linkedin"></i>
                </LinkedinShareButton>
                <EmailShareButton
                  style={{ height: "16px" }}
                  url={`${COPY_BASE_URL}feed/${playerData.postId || postIdUrl}`}
                >
                  {" "}
                  <i className="far fa-envelope" title="email"></i>
                </EmailShareButton>
                <button>
                  <i
                    // onClick={() => handleCopy(playerData)}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${COPY_BASE_URL}feed/${playerData.postId || postIdUrl}`
                      );
                      if (!toast.isActive(id)) {
                        toast({
                          id,
                          position: "top",
                          title: "Copied",
                          status: "success",
                          duration: 3000,
                          isClosable: true,
                        });
                      }
                    }}
                    className="fas fa-link"
                    title="copy link"
                  ></i>
                </button>
              </div>
            </PopoverContent>
          </Popover>
        </li>

        <video
          className="story_player"
          style={{ height: "100%", borderRadius: "12px", width: "100%" }}
          src={videoUrl}
          type="video/mp4"
          controls="hidden"
          playsInline
          controlsList="nodownload noplaybackrate"
          disablePictureInPicture
          autoPlay
        ></video>
        <div
          style={{
            background:
              "transparent linear-gradient(180deg, #231F2000 0%, #000000 100%) 0% 0% no-repeat padding-box",
            position: "absolute",
            bottom: "0px",
            width: "100%",
            height: "150px",
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
          }}
        ></div>
      </div>

      {isOwnPostForReport ? (
        <ReportModalPage
          handleModalStatus={handleModalStatus}
          fromSelfPost
          setDeleteModal={setDeleteModal}
          deleteModal={deleteModal}
          isOwnPostForReport={isOwnPostForReport}
          postId={
            playerData?.postId
              ? playerData?.postId
              : playerData?.stories[0]?.postId
          }
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Storystatus;
