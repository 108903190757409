/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useAuth } from "../../context/AuthContext";
import ReCAPTCHA from "react-google-recaptcha";
import isEmpty from "is-empty";

import { Logo } from "./shared/Logo";
import AppStore from "../../assets/img/appstore.svg";
import PlayStore from "../../assets/img/googleplay.svg";
import { accountTokenRefresh, accountVerifyLogin, userLogin } from "./Service";
import { capabilities } from "../../utils";
import LocalStorageService from "../../utils/LocalStorageService";
import { useUser } from "../../context/UserProvider";
import { ClientJS } from "clientjs";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import { Box, Button } from "@chakra-ui/react";
import { getUserProfile } from "../../components/layout/Service";
import { Footer } from "../../components/shared/Footer";
import { APP_STORE_LINK, PLAY_STORE_LINK } from "../../utils/config";

const fpPromise = FingerprintJS.load({
  token: "vuYU13dLdP0lfsBy431V",
});

export default function Login() {
  const [userLoginDetail, setUserLoginDetail] = useState({
    username: "",
    password: "",
  });
  const {
    firebaseToken,
    setAuthSecretHandler,
    getSH512Salt,
    getArgon2Password,
    argonPassword,
    toUTF8Array,
    setIsCaptchaShow,
    setAuthSecretAfterLoginHandler,
    setChallenge,
    getChallengeHandler,
  } = useAuth();
  const { onSubmitUserData } = useUser();
  const [error, setError] = useState("");

  // const history = useHistory();
  const [captchaValue, setCaptchaValue] = useState("");
  const [visitorId, setVisitorId] = useState("");
  const [isLogging, setIsLogging] = useState(false);
  const [showEye, setShowEye] = useState(false);
  const [catchaRef, setCaptchaRef] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserLoginDetail((prev) => ({ ...prev, [name]: value.trim() }));
    if (name === "password") {
      toUTF8Array(value.trim());
    }
    setError("");
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    const { password } = userLoginDetail;
    const salt = getSH512Salt(password.trim());
    getArgon2Password(salt);
  };

  const checkShaHandler = (e) => {
    const { value } = e.target;
    value.trim();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { username, password } = userLoginDetail;
    try {
      setError("");
      setIsLogging(true);
      const salt = getSH512Salt(password.trim());
      getArgon2Password(salt);
      const clientJs = new ClientJS();
      var browserVersion = clientJs.getBrowserVersion(); // Get Browser Version

      const payload = {
        token: firebaseToken ? firebaseToken : "NO_FIREBASE_TOKEN",
        username,
        deviceId: visitorId,
        deviceName: `${clientJs.getBrowser()}_${browserVersion.replaceAll(
          ".",
          "_"
        )}`,
        country: "IN",
      };
      getChallengeHandler();
      const res = await userLogin(payload);
      if (!isEmpty(res?.response)) {
        catchaRef.reset();
        localStorage.clear();
        setIsLogging(false);
        return setError(res?.response?.data?.message);
      }
      accountVerifyCaptchaLoginHandler();
    } catch (error) {
      setError("Failed to log in", "checkFaild");
    }
  };

  const accountTokenRefreshHandler = async () => {
    const payload = {
      gcmRegistrationId: firebaseToken ? firebaseToken : "aaaaaaaaaa",
      webSocketChannel: true,
    };
    await accountTokenRefresh(payload);
  };

  const accountVerifyCaptchaLoginHandler = async (type) => {
    const authKeyTemp = localStorage.getItem("secret");
    const payload = {
      capabilities,
      discoverableByPhoneNumber: true,
      fetchesMessages: true,
      pin: null,
      registrationId: 0,
      registrationLock: null,
      signalingKey: null,
      unidentifiedAccessKey: "",
      unrestrictedUnidentifiedAccess: false,
      video: true,
      voice: true,
      isLogin: true,
      password: argonPassword,
      AuthKey: authKeyTemp,
    };
    if (type === "NO_TOKEN") {
      payload.challenge = type;
    } else {
      payload.captcha = captchaValue;
    }
    const res = await accountVerifyLogin(userLoginDetail.username, payload);

    if (res?.response?.status === 402) {
      localStorage.clear();
      catchaRef.reset();
      return setIsCaptchaShow(true);
    } else if (res?.response?.status === 401) {
      localStorage.clear();
      catchaRef.reset();
      setIsLogging(false);
      if (type === "NO_TOKEN") {
        return;
      }
      return setError("Invalid credentials!");
    }
    if (!isEmpty(res)) {
      setTimeout(() => {
        setIsLogging(false);
        localStorage.setItem("isTokenValid", true);
        setAuthSecretAfterLoginHandler(res?.uuid);
        getUserProfileHandler(res?.uuid);
        setChallenge("");
        onSubmitUserData(res);
        accountTokenRefreshHandler();
        LocalStorageService.setItem("userData", res);
      }, 500);
    } else {
      localStorage.clear();
      setError("Something went wrong!");
      setIsLogging(false);
      catchaRef.reset();
    }
  };

  const getUserProfileHandler = async (uuid) => {
    const res = await getUserProfile(uuid);
    if (!isEmpty(res) && res.status !== 404) {
      LocalStorageService.setItem("userProfileData", res);
      const lastPath = localStorage.getItem("lastPath");
      if (lastPath) {
        return (window.location.href = lastPath);
      }
      window.location.href = "/feed";
      setIsLogging(false);
    }
  };

  useEffect(() => {
    if (captchaValue) {
      setAuthSecretHandler(userLoginDetail.username);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captchaValue]);

  useEffect(() => {
    const fetchId = () => {
      fpPromise
        .then((fp) => fp.get())
        .then((result) => setVisitorId(result.visitorId));
    };

    fetchId();
    return () => fetchId();
  }, []);

  const handleEye = () => {
    setShowEye(!showEye);
    const res = document.getElementById("password123");
    if (res.type === "password") {
      res.type = "text";
    } else {
      res.type = "password";
    }
  };

  return (
    <>
      <div className="login_wrapper">
        <div className="login_button_wraper">
          <Button className="like_influ">
            <i className="icon video_button" />
            <span>Like an Influencer</span>
          </Button>
          <Box className="login_box_wrap">
            <div className="wrap">
              <div className="card">
                <h5>Sign in</h5>
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      onChange={handleChange}
                      required
                      value={userLoginDetail.username}
                      maxLength={30}
                    />
                  </Form.Group>
                  <div style={{ height: "22px" }}>
                    {error === "Note: username should be in lowercase" && (
                      <p
                        style={{ fontSize: "12px" }}
                        className="text-danger text-left mb-2"
                      >
                        {error}
                      </p>
                    )}
                  </div>

                  <Form.Group style={{ position: "relative" }} className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      id="password123"
                      name="password"
                      onChange={handleChange}
                      required
                      onBlur={checkShaHandler}
                      value={userLoginDetail.password}
                      maxLength={30}
                    />
                    <p
                      className={`icon ${showEye ? "eye" : "eye_hidden"}`}
                      onClick={handleEye}
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "53%",
                        cursor: "pointer",
                      }}
                    ></p>
                  </Form.Group>

                  {/* <div className="forgot_wrap v2">
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Remember me" />
                  </Form.Group>
                  <Link to="/forgot-password">Forgot Password?</Link>
                </div> */}

                  {error && (
                    <p className="text-danger text-center mb-2">{error}</p>
                  )}

                  <div className="captcha_wrap login">
                    <ReCAPTCHA
                      sitekey="6Lf-0i0hAAAAANh6DrDTbe4KikGJHiC1CBeVsXRR"
                      ref={(r) => setCaptchaRef(r)}
                      onChange={handleCaptchaChange}
                      size="normal"
                    />
                  </div>

                  <Button
                    isDisabled={!!error || !captchaValue}
                    isLoading={isLogging}
                    loadingText="Loging"
                    type="submit"
                  >
                    LOGIN
                  </Button>
                </Form>
              </div>

              <div className="app_get_wrap">
                <div className="download_app">
                  <a href={PLAY_STORE_LINK} target="_blank" rel="noreferrer">
                    <img src={PlayStore} alt="app store" />
                  </a>
                  <a href={APP_STORE_LINK} target="_blank" rel="noreferrer">
                    <img className="ml-35" src={AppStore} alt="app store" />
                  </a>
                </div>
              </div>
            </div>
          </Box>
        </div>

        <Footer fromAbout extraItems />
      </div>
    </>
  );
}
