import React from "react";
import { Navbar } from "react-bootstrap";
import { Logo } from "../../pages/auth/shared/Logo";
import { Link } from "react-router-dom";

export default function PublicNavbar() {
  return (
    <>
      <div className="publick_navbar">
        <Link className="navbar-brand" to="/">
          <Logo />
        </Link>
        <Navbar>
          {/* <Container>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Nav>
                <Link className="nav-link" to="/login">
                  Log in
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container> */}
        </Navbar>
      </div>
    </>
  );
}
