import React from "react";
import {
  Grid,
  GridItem,
  HStack,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
} from "@chakra-ui/react";

export default function FeedSkeleton(props) {
  const { isFeedLoading } = props;
  return (
    <>
      {isFeedLoading && (
        <Grid
          h="full"
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(4, 1fr)"
          gap={4}
          mt="5"
          mb="5"
          className="feed_grid_row feed-loading-wrap"
        >
          <GridItem colSpan={2}>
            <div className="feed_grid_col">
              <div className="feed_body">
                <div className="feed_content">
                  <SkeletonText
                    mt="4"
                    noOfLines={1}
                    spacing="4"
                    position="absolute"
                  />
                  <Skeleton h="410"></Skeleton>
                  <div className="users">
                    <ul>
                      <li className="mb-0">
                        {/* <img src={DP} alt="" /> */}

                        <HStack spacing="10px" width="100%">
                          <SkeletonCircle size="7" />
                        </HStack>
                      </li>
                      <li>
                        {/* <img src={DP} alt="" /> */}

                        <HStack spacing="10px" width="100%">
                          <SkeletonCircle size="7" />
                          <SkeletonText noOfLines={1} spacing="4" height="10" />
                        </HStack>
                      </li>
                    </ul>
                    {/* <span className="views_count">
              <i className="icon view"></i>
              <SkeletonText noOfLines={1} spacing="4" />
            </span> */}
                  </div>
                </div>
                <div className="feed_bottom">
                  <Skeleton height="30px" maxWidth="100" />
                  <Skeleton height="40px" mt="3" borderRadius="25" />
                </div>
              </div>
            </div>
          </GridItem>
          <GridItem colSpan={2}>
            <div className="feed_grid_col">
              <div className="feed_body">
                <div className="feed_content">
                  <SkeletonText
                    mt="4"
                    noOfLines={1}
                    spacing="4"
                    position="absolute"
                  />
                  <Skeleton h="410"></Skeleton>
                  <div className="users">
                    <ul>
                      <li className="mb-0">
                        {/* <img src={DP} alt="" /> */}

                        <HStack spacing="10px" width="100%">
                          <SkeletonCircle size="7" />
                        </HStack>
                      </li>
                      <li>
                        {/* <img src={DP} alt="" /> */}

                        <HStack spacing="10px" width="100%">
                          <SkeletonCircle size="7" />
                          <SkeletonText noOfLines={1} spacing="4" height="10" />
                        </HStack>
                      </li>
                    </ul>
                    {/* <span className="views_count">
              <i className="icon view"></i>
              <SkeletonText noOfLines={1} spacing="4" />
            </span> */}
                  </div>
                </div>
                <div className="feed_bottom">
                  <Skeleton height="30px" maxWidth="100" />
                  <Skeleton height="40px" mt="3" borderRadius="25" />
                </div>
              </div>
            </div>
          </GridItem>
        </Grid>
      )}
    </>
  );
}
